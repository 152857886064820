import { useMemo } from "react";
import PageLayout from "../../components/page-layout";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page51() {

    const answer = useAnswer('m-4-3', PLAYER.CHILD)

    const content = useMemo(() => {
        switch (answer) {
            case '0':
                return 'Szczera komunikacja jest ważna. Pomyśl jak reagować, kiedy jest Ci trudno przyjąć to co mówi rodzic.';

            case '1':
                return 'Równowaga w życiu jest ważna. Jednak często jedna pozytywna informacja nie równoważy jednej negatywnej.';

            case '2':
                return 'Bycie docenianym jest ważne. Ważne jest też docenianie innych.';
        }
    }, [answer]);

    return <PageLayout
        previous={getGameRoute(PAGES.p50)}
        next={getGameRoute(PAGES.p52)}
    >
        {content}
    </PageLayout>
}
