import { useMemo } from "react";

const Loader: React.FC<{}> = () => {
    const style: React.CSSProperties = useMemo(() => {
        return {
            position: 'absolute',
            width: '100%',
            minHeight: '20vh',
            top: 0,
            bottom: 0,

            backgroundSize: 'auto 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
        }
    }, [])

    return <div  style={style} aria-busy="true" role="alert" aria-label="Ładowanie danych">
    </div>
}

export default Loader;
