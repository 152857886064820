import styled from "styled-components";
import Box from "../../components/box";
import PageFooter from "../../components/footer";
import NiceButton from "../../components/nice-button";
import { getHomeRoute } from "../routes";

const Container = styled.div`
min-height: 100vh;
width: 100%;

display: flex;
flex-direction: column;
align-items: stretch;

main {
    width: 95%;
    max-width: 1520px;
    margin: 0 auto;
    flex: 1 1 auto;
    position: relative;
    padding: 5vh 0;

    display: flex;
    align-items: center;

    .wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 15% 85%;
        gap: .5em
    }

    nav {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 auto;
    }
    video{
        width: 100%;
    }
}

    footer {
        background: white;
        flex: 0 0 100px;
    }

    .download {
        line-height:1.5em;

        h4 {
            margin-bottom: .2em;
        }

        li {
            list-style: none;
            padding-left: .5em;
        }
    }
`;

export default function ForTeacherPage() {
    return <Container>
        <main>
            <div className="wrapper">

                <nav>
                    <NiceButton to={getHomeRoute()} label="Wróć" />
                </nav>
                <Box>
                    <div>
                        <video src="/assets/dla-nauczyciela.mp4" controls></video>
                    </div>
                    <div className="download">
                        <h3>Pliki do pobrania</h3>

                        <h4>Scenariusz zebrania EduChoice</h4>
                        <ul>
                            <li>
                                <a href="/assets/scenariusze/scenariusz-zebrania-educhoice.pdf" download='Scenariusz zebrania EduChoice.pdf'>Scenariusz zebrania EduChoice (plik pdf)</a>
                            </li>
                            <li>
                                <a href="/assets/scenariusze/scenariusz-zebrania-educhoice.docx" download='Scenariusz zebrania EduChoice.docx'>Scenariusz zebrania EduChoice (plik docx)</a>
                            </li>
                        </ul>

                        <h4>Zebranie z rodzicami EduChoice - prezentacja</h4>
                        <ul>
                            <li>
                                <a href="/assets/scenariusze/zebranie-z-rodzicami-educhoice-prezentacja.pdf" download='Zebranie z rodzicami EduChoice - prezentacja.docx'>Zebranie z rodzicami EduChoice - prezentacja (plik pdf)</a>
                            </li>
                            <li>
                                <a href="/assets/scenariusze/zebranie-z-rodzicami-educhoice-prezentacja.pptx" download='Zebranie z rodzicami EduChoice - prezentacja.pptx'>Zebranie z rodzicami EduChoice - prezentacja (plik pptx)</a>
                            </li>
                        </ul>

                        <h4>Scenariusz lekcji EduChoice</h4>
                        <ul>
                            <li>
                                <a href="/assets/scenariusze/scenariusz-lekcji-educhoice.pdf" download='Scenariusz lekcji EduChoice.pdf'>Scenariusz lekcji EduChoice (plik pdf)</a>
                            </li>
                            <li>
                                <a href="/assets/scenariusze/scenariusz-lekcji-educhoice.docx" download='Scenariusz lekcji EduChoice.docx'>Scenariusz lekcji EduChoice (plik docx)</a>
                            </li>
                        </ul>

                        <h4>Scenariusz lekcji EduChoice - prezentacja</h4>
                        <ul>
                            <li>
                                <a href="/assets/scenariusze/scenariusz-lekcji-educhoice-prezentacja.pdf" download='Scenariusz lekcji EduChoice - prezentacja.docx'>Scenariusz lekcji EduChoice - prezentacja (plik pdf)</a>
                            </li>
                            <li>
                                <a href="/assets/scenariusze/scenariusz-lekcji-educhoice-prezentacja.pptx" download='Scenariusz lekcji EduChoice - prezentacja.pptx'>Scenariusz lekcji EduChoice - prezentacja (plik pptx)</a>
                            </li>
                        </ul>
                    </div>
                </Box>
            </div>
        </main>
        <PageFooter />
    </Container >
}
