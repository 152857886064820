import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page88() {
    return <MultipleAnswerQuestionLayout previous={getGameRoute(PAGES.p87)} next={getGameRoute(PAGES.p89)} player={PLAYER.PARENT}>
        <MultipleAnswerQuestion
            question={"Jakie zachowania przed i w trakcie egzaminu są, Twoim zdaniem, najbardziej przydatne? (wskaż maksymalnie 3 odpowiedzi):"}
            questionId={"m-8-2"}
            limit={3}
            answers={[
                "Dzień przed egzaminem pójdź wcześniej spać – wyśpij się.",
                "Przygotuj sobie czarne długopisy i ewentualnie inne przybory.",
                "Czytaj uważnie polecenia, nawet jeśli masz pewność, że wiesz co masz zrobić.",
                "Pilnuj czasu podczas egzaminu, aby zdążyć ze wszystkim.",
                "Pisz wyraźnie, tak aby egzaminator mógł odczytać.",
                "W zadaniach zamkniętych wyeliminuj niepoprawne odpowiedzi.",
                "W części z języka obcego dwa razy uważnie wysłuchaj nagrania.",
                "Zostaw czas na sprawdzenie wszystkiego.",
            ]} />

    </MultipleAnswerQuestionLayout>
}
