import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page69() {
    return <PageLayout previous={getGameRoute(PAGES.p68)} next={getGameRoute(PAGES.p70)} nextLabel={'Kolejna część'}
    decors={DECOR_CONFIG.OPEN_QUESTIONS}>
        <p>Opowiedzcie sobie jakie wybraliście profile szkół, dlaczego właśnie te.</p>
        <p>Rodzicu, opowiedz o swojej szkole.</p>
        <p>Co to była za szkoła, jak to się stało, że do niej trafiłeś, jak ją oceniasz? </p>
    </PageLayout>
}
