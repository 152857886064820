import PlayerInfoLayout from "../../components/player-info-layout";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page52() {
    return <PlayerInfoLayout
        previous={getGameRoute(PAGES.p51)}
        next={getGameRoute(PAGES.p53)}
        player={PLAYER.PARENT}
    />
}
