import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page17Male() {
    return <SingleAnswerLayout previous={getGameRoute(PAGES.p16)} next={getGameRoute(PAGES.p18)} player={PLAYER.CHILD}>
        <SingleAnswerQuestion
        question={"Jak oceniasz wyniki Kazika?"}
        questionId={"m-2-1"}
        answers={[
            "Bardzo dobre.",
            "Dobre.",
            "Niezłe.",
            "Słabe.",
            "Bardzo słabe."
        ]}
        />
    </SingleAnswerLayout>
}
