import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page10Male() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p9)}
        next={getGameRoute(PAGES.p11)}
        player={PLAYER.PARENT}
    >
        <SingleAnswerQuestion
            question={"Kto, Twoim zdaniem, odpowiada za podjęcie decyzji do jakiej szkoły kandydować?"}
            questionId={"m-1-1"}
            answers={[
                'Wyłącznie uczeń - to ma być jego decyzja.',
                'Wyłącznie rodzic - zna swoje dziecko i zna życie, wie co będzie dla niego najlepsze.',
                'Wspólnie uczeń i rodzic - porozmawiajmy, pomyślmy i wybierzmy.',
                'Los - od nas mało zależy, nie wiemy czy egzamin będzie trudny ani ilu chętnych będzie startować do wybranej szkoły.'
            ]}
        />

    </SingleAnswerLayout >
}
