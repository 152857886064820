import { useMemo } from "react";
import PageLayout from "../../components/page-layout";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page56() {

    const answer = useAnswer('m-4-2', PLAYER.PARENT)

    const content = useMemo(() => {
        switch(answer) {
            case '0':
                return 'Kary i nagrody działają na krótko. Po pewnym czasie zaczynają przynosić wiele negatywnych konsekwencji. Więcej informacji na temat znajdziesz w Bazie Wiedzy.';

            case '1':
                return 'Świetnie, że lubisz rozmawiać. To trudna sztuka, często wymaga cierpliwości i otwartości. O tym jak rozmawiać, żeby być zrozumianym znajdziesz więcej w Bazie Wiedzy.'
        }
    }, [answer]);

    return <PageLayout
        previous={getGameRoute(PAGES.p55)}
        next={getGameRoute(PAGES.p57)}
    >
        {content}
    </PageLayout>
}
