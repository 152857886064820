import CompareAnswersLayout from "../../components/compare-answers-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import {MODULE_1_QUESTIONS} from '../../modules/main/hooks/use-final-score'

export default function Page13() {
    return <CompareAnswersLayout
        previous={getGameRoute(PAGES.p12)}
        next={getGameRoute(PAGES.p14)}
        questions={MODULE_1_QUESTIONS}
    />
}
