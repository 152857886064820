import OrderQuestionLayout from "../../components/order-question-layout.tsx";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page25Male() {
    return <OrderQuestionLayout
        previous={getGameRoute(PAGES.p24)}
        next={getGameRoute(PAGES.p26)}
        questionId='m-2-2'
        player={PLAYER.PARENT}
        instruction="Jak myślisz, co powinno być najważniejsze dla Kazika w tym roku? Ustaw wartości od najważniejszej do najmniej istotnej."
        items={[
            "Wysoki wynik egzaminu kończącego szkołę podstawową.",
            "Rozwijanie pasji/hobby.",
            "Komfort psychiczny.",
            "Akceptacja i uznanie kolegów i koleżanek.",
            "Wypoczynek.",
            "Rozrywka.",
            "Poczucie niezależności.",
        ]}
    />
}
