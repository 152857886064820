import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page12Male() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p11)}
        next={getGameRoute(PAGES.p13)}
        player={PLAYER.PARENT}
    >
        <SingleAnswerQuestion
            question={"W jakim stopniu postawa taty Kazika jest Ci bliska?"}
            questionId={"m-1-3"}
            answers={[
                "W bardzo wysokim, mam identyczne odczucia.",
                "W wysokim.",
                "W średnim.",
                "W niskim.",
                "W ogóle nie podzielam emocji taty Kazika."
            ]}
        />
        <SingleAnswerQuestion
            question={"Dlaczego?"}
            questionId={"m-1-4"}
            answers={[
                "Bo mam obawy związane z końcem szkoły podstawowej.",
                "Bo nie mam obaw związanych z końcem szkoły podstawowej.",
                "Bo słyszę i widzę jak inni rodzice już intensywnie planują karierę edukacyjną swoich dzieci.",
                "Bo nie słyszę i nie widzę, żeby inni rodzice już intensywnie planowali karierę edukacyjną swoich dzieci.",
                "Bo przejmuję się tym, czy moje dziecko rozumie powagę sytuacji i jest zmotywowane do działania.",
                "Bo nie przejmuję się tym, czy moje dziecko rozumie powagę sytuacji i jest zmotywowane do działania.",
                "Bo uważam, że egzamin to ważny moment w życiu ucznia i trzeba to potraktować poważnie.",
                "Bo uważam, że nie ma co się przejmować egzaminem, on w rzeczywistości o niczym nie przesądza."
            ]}
        />
    </SingleAnswerLayout >
}
