import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page70Female() {
    return <PageLayout
        previous={getGameRoute(PAGES.p69)}
        next={getGameRoute(PAGES.p71)}
        decors={DECOR_CONFIG.STORY_3_FEMALE}
    >
        <p>Egzamin zbliża się wielkimi krokami. Krysia z Tadkiem i Sandrą spotykają się, żeby zaplanować wspólne przygotowanie do egzaminu.</p>
        <p className='talk-phase'> Dobra, musimy to ogarnąć, zacznijmy od wybrania celów, które muszą być… - zaczyna Krysia, ale nie bardzo wiedziała jakie powinny być te cele.</p>
        <p className='talk-phase'>Skonkretyzowane, mierzalne, osiągalne, istotne, określone w czasie - płynnie recytuje Sandra, bez odrywania wzroku od telefonu.</p>
        <p>Krysia spogląda na Tadka, który wygląda na równie zdziwionego, jak ona sama.</p>
        <p className='talk-phase'> No co? W przyszłości będę prezeską firmy. Widziałam tutorial i wiem co to SMART leszcze - mówi oburzona Sandra.</p>
        <p>Krysia zastanawia się, jak uspokoić Sandrę. Na szczęście wtrąca się Tadek.</p>
        <p className='talk-phase'> To może zrobimy checklistę, jak nasi ojcowie, damy radę? - proponuje.</p>
        <p className='talk-phase'> No… chyba tak - niepewnie odpowiada Krysia.</p>

    </PageLayout>
}
