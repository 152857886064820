import adamSrc from './adam.png';
import jurekSrc from './jurek.png';
import kazikSrc from './kazik.png';
import krysiaSrc from './krysia.png';
import filozofSrc from './filozof.png';
import sandraSrc from './sandra.png';
import tadzikSrc from './tadzik.png';

export const HEADS = {
    ADAM: adamSrc,
    JUREK: jurekSrc,
    KAZIK: kazikSrc,
    KRYSIA: krysiaSrc,
    FILOZOF: filozofSrc,
    SANDRA: sandraSrc,
    TADZIK: tadzikSrc
}
