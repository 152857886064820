import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page60() {
    return <PageLayout
        previous={getGameRoute(PAGES.p59)}
        next={getGameRoute(PAGES.p61)}
        nextLabel="Kolejna część"
        decors={DECOR_CONFIG.OPEN_QUESTIONS}
    >
        <p>
            Pomyślcie, za co się doceniacie. Powiedzcie to sobie. Możecie też napisać na kartkach i się nimi wymienić.
        </p>
        <h3>Wyzwanie:</h3>
        <p>
        W najbliższy weekend skupcie się na docenianiu się. Bądźcie uważni i jak tylko uznacie, że druga strona zrobiła coś wartego docenienia, nie wahajcie się i powiedzcie jej o tym.
        </p>
    </PageLayout>
}
