import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page8Male() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p7)}
        next={getGameRoute(PAGES.p9)}
        player={PLAYER.CHILD}
    >
        <SingleAnswerQuestion
            question={"W jakim stopniu emocje i postawa Kazika są Ci bliskie?"}
            questionId={"m-1-3"}
            answers={[
                "W bardzo wysokim, mam identyczne odczucia.",
                "W wysokim.",
                "W średnim.",
                "W niskim.",
                "W ogóle nie podzielam emocji Kazika."
            ]}
        />
        <SingleAnswerQuestion
            question={"Dlaczego?"}
            questionId={"m-1-4"}
            answers={[
                "Bo mam obawy związane z końcem szkoły podstawowej i wejściem na nowy etap edukacyjny.",
                "Bo nie mam obaw związanych z końcem szkoły podstawowej i wejściem na nowy etap edukacyjny.",
                "Bo słyszę i widzę jak inni uczniowie już intensywnie planują co po podstawówce.",
                "Bo nie słyszę i nie widzę, żeby inni uczniowie już intensywnie planowali co po podstawówce.",
                "Bo przejmuję się tym, jak mi pójdzie i co będzie.",
                "Bo nie przejmuję się tym, jak mi pójdzie i co będzie.",
                "Bo uważam, że egzamin to ważny moment w życiu ucznia i trzeba to potraktować poważnie.",
                "Bo uważam, że nie ma co się przejmować egzaminem, on w rzeczywistości o niczym nie przesądza."
            ]}
        />
    </SingleAnswerLayout >
}
