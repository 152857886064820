import OrderQuestionLayout from "../../components/order-question-layout.tsx";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page67() {
    return <OrderQuestionLayout
        previous={getGameRoute(PAGES.p66)}
        next={getGameRoute(PAGES.p68)}
        questionId={'m-5-2'}
        player={PLAYER.CHILD}
        instruction={"Czym według Ciebie powinno się kierować przy wyborze szkoły? Uszereguj kryteria od najważniejszego do najmniej istotnego."}
        items={[
            "Pozycją w rankingu.",
            "Dostępnymi profilami klas.",
            "Lokalizacją.",
            "Opiniami w Internecie, wśród znajomych.",
            "Tym jakich języków można się w niej uczyć.",
            "Towarzystwem - kto się w niej uczy.",
            "Inne, jakie?",
        ]} />
}
