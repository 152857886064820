import { useMemo } from "react";
import PageLayout from "../../components/page-layout";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page58() {

    const answer = useAnswer('m-4-3', PLAYER.PARENT)

    const content = useMemo(() => {
        switch (answer) {
            case '0':
                return 'Szczera informacja zwrotna to podstawa. Ważne aby była konstruktywna i zachęcała do dalszej pracy. Dowiedz się jak to robić - więcej znajdziesz w Bazie Wiedzy.';

            case '1':
                return 'Równowaga w życiu jest ważna. Jednak jedna pozytywna informacja nie równoważy jednej negatywnej. Więcej na ten temat znajdziesz w Bazie Wiedzy.';

            case '2':
                return 'Szczera komunikacja między rodzicem a dzieckiem jest bardzo ważna. Sprawdź, czy prawidłowo komunikujesz mu swoje oczekiwania. Więcej na ten temat znajdziesz w Bazie Wiedzy.';
        }
    }, [answer]);

    return <PageLayout
        previous={getGameRoute(PAGES.p57)}
        next={getGameRoute(PAGES.p59)}
    >
        {content}
    </PageLayout>
}
