import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    background: ${(props) => props.theme.boxBackground};
    min-height: 75vh;



    display: flex;
        justify-content: center;
        align-items: center;


    .inner {
        width: 86%;
        padding: 2% 7%;
    }
`

export default function Box({ children }: React.PropsWithChildren<{}>) {
    return <Container>
        <div className="inner">
            {children}
        </div>
    </Container>
}
