import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page38Male() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p37)}
        next={getGameRoute(PAGES.p39)}
        player={PLAYER.PARENT}
    >
        <MultipleAnswerQuestion
            question={"Jak Kazik może zadbać o lepszy nastrój? (wskaż maksymalnie 3 odpowiedzi)"}
            questionId={"m-3-1"}
            limit={3}
            answers={[
                "Zacząć regularnie uprawiać jakiś sport – uwalniające się endorfiny poprawiają nastrój.",
                "Ułożyć plan dnia – dzięki temu będzie wiedział, co i kiedy powinien robić, nie nawarstwią mu się zaległości.",
                "Zadbać o sen, mniej niż 8 godzin snu powoduje obniżenie nastroju.",
                "Zacząć się regularnie i zdrowo odżywiać. Jeśli jest głodna, to trudno się skupić, i pogarsza się nastrój.",
                "Spotykać się ze znajomymi – naukowcy są zgodni, że dobre relacje z ludźmi dają nam poczucie szczęścia.",
                "Ograniczyć czas przed ekranem komputera i smartfona – męczą wzrok i często pogarszają nastrój. ",
                "Zacząć próbować nowych aktywności, poszukać hobby, np. gra na instrumencie, albo włączenie się w wolontariat – dzięki temu będzie mógł się rozwijać i mieć poczucie sensu i satysfakcji."
            ]}
        />
    </MultipleAnswerQuestionLayout>
}
