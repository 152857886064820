import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { getBeRoute, getHomeRoute, getLetsTalkRoute, getGameStartRoute, GetKnowledgeRoute, GetIntroRoute } from './routes/routes';
import React, { Suspense } from 'react';
import Loader from './components/loader';
import GameRouter from './routes/game/router';
import LetsTalkPage from './routes/innovation';
import KnowledgePage from './routes/knowledge';
import ForTeacherPage from './routes/for-teacher';


const HomePage = React.lazy(() => import('./routes/home'));
const IntroPage = React.lazy(() => import('./routes/intro'));
const StartGamePage = React.lazy(() => import('./routes/start-game'));
// const LetsTalkPage = React.lazy(() => import('./routes/lets-talk'));

export default function App() {


    return (
        <>
            <BrowserRouter>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path={getHomeRoute()} element={<HomePage />} />
                        <Route path={GetKnowledgeRoute()} element={<KnowledgePage />} />
                        <Route path={getGameStartRoute()} element={<StartGamePage />} />
                        <Route path={GetIntroRoute()} element={<IntroPage />} />
                        <Route path={getLetsTalkRoute()} element={<LetsTalkPage />} />
                        <Route path={getBeRoute()} element={<ForTeacherPage />} />
                        {GameRouter()}
                        <Route element={<HomePage />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>
    )
}
