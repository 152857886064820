import ScrollToMe from '@mborecki/react-scroll-to-me';
import { useEffect, useReducer } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type Props = {
    question: string,
    questionId: string,
    answers: string[],
    limit?: number,
    force?: number
}

const Container = styled.div`
    fieldset {
        border: none;
        margin-top: 2em;

        legend {
            ${props => props.theme.questionTextStyle}
        }

        label {
            padding: .5em;
            cursor: pointer;

            &:focus-within {
                outline: 1px dotted black;
            }

            .item {
                display: grid;
                grid-template-columns: 30px 1fr;
                gap: 10px;
            }

            span {
                display: inline-block;
                padding: .5em;
                border: 1px solid black;
            }

            input {
                opacity: 0;
                width: 0;
                height: 0;
                position: absolute;
            }

            .marker {
                width: 30px;
                height: 30px;
                border: 1px solid black;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                .x {
                    display: none;
                }
            }

            &:hover {
                .marker, span {
                    background: #FCD779
                }
            }

            input:checked + .item {
                .marker, span {
                    background: #FCD779
                }

                .marker {
                    .x {display: block}
                }
            }
        }
    }
`;

export default function MultipleAnswerQuestion({ question, questionId, answers, limit, force }: Props) {

    const { register, formState } = useFormContext();
    const [trigger, ping] = useReducer((state) => state + 1, 0)


    const { errors } = formState;


    useEffect(() => {
        const { errors } = formState;
        const index = Object.keys(errors).findIndex(key => key === questionId)

        if (index === 0) {
            ping();
        }
    }, [formState, questionId, ping])


    console.log({ errors });

    return <Container>
        <ScrollToMe trigger={trigger} />
        <fieldset>
            <legend>{question}</legend>
            {errors[questionId] && (errors[questionId].type === 'limit') && (
                <div><span className='error-info' role="alert">{`Proszę zaznaczyć maksymalnie ${limit} odpowiedzi.`}</span></div>
            )}
            {errors[questionId] && (errors[questionId].type === 'force') && (
                <div><span className='error-info' role="alert">{`Proszę zaznaczyć  ${force} odpowiedzi.`}</span></div>
            )}
            {errors[questionId] && (errors[questionId].type === 'noEmpty') && (
                <div><span className='error-info' role="alert">{`Proszę zaznaczyć odpowiedź.`}</span></div>
            )}
            {
                answers.map((text, index) => {
                    return <div key={index}>
                        <label>
                            <input type="checkbox" value={index}
                                aria-invalid={errors[questionId] ? "true" : "false"}
                                {...register(questionId, {
                                    validate: {
                                        limit: (value) => {
                                            console.log({ value, limit });
                                            if (limit && value.length > limit) {
                                                return false;
                                            }
                                        },
                                        force: (value) => {
                                            if (force && value.length !== force) {
                                                return false;
                                            }
                                        },
                                        noEmpty: (value) => {
                                            if (value.length === 0) {
                                                return false;
                                            }
                                        }
                                    }
                                })} />
                            <div className="item">
                                <div className="marker"><div className="x" aria-hidden={true}>X</div></div>
                                <span>
                                    {text}
                                </span>
                            </div>
                        </label>
                    </div>
                })
            }
        </fieldset>
    </Container>
}
