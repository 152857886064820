import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import GameProgress from "../game-progress";

type Props = {
    left?: ReactElement,
    right?: ReactElement,
    decors?: Decor[],
    hideProgress?: boolean
}

export type Decor = {
    side: string,
    position: number,
    src: string;
}

const Container = styled.div`
    max-width: 1550px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    min-height: 95vh;
    padding-bottom: 5vh;

    .progress {
        padding: .5em 0 1.5em;
    }

    .columns {
        display: grid;
        grid-template-columns: 15% 1fr 15%;
        gap: 10px;

        .left-nav {
            z-index: 1;
            align-self: flex-start;

            display: flex;
            justify-content: flex-start;
        }

        .right-nav {
            z-index: 1;
            align-self: flex-end;

            display: flex;
            justify-content: flex-end;
        }
    }
`;

export default function ColumnLayout({ children, left, right, decors, hideProgress }: React.PropsWithChildren<Props>) {

    const containerRef = useRef(null);

    return <Container>

        <div className="progress">
            {!hideProgress && <GameProgress />}
        </div>
        <div className="columns" ref={containerRef}>
            <nav className="left-nav">
                {left}
            </nav>
            {children}
            <nav className="right-nav">
                {right}
            </nav>
            {
                decors && decors?.map(decor => {
                    return <DecorComponent key={decor.src} decor={decor} parentRef={containerRef} />
                })
            }
        </div>
    </Container>
}

type DecorProps = {
    decor: Decor
}

const DecorStyled = styled.div<DecorProps>`
    position: fixed;
    pointer-events: none;
    height: 90vh;

    background: url('${props => props.decor.src}') no-repeat;
    background-size: 100% auto;

    ${props => {
        return `background-position: 50% ${props.decor.position * 100}%;`
    }
    }
`;

function DecorComponent({ decor, parentRef }: DecorProps & { parentRef: any }) {

    const [style, setStyle] = useState<React.CSSProperties>({});

    const update = useCallback(() => {
        if (parentRef.current) {
            const rect: DOMRect = parentRef.current.getBoundingClientRect();

            if (decor.side === 'left') {
                setStyle({
                    top: (5) + 'vh',
                    left: rect.x + 'px',
                    width: (rect.width * .2) + 'px'
                })
            }

            if (decor.side === 'right') {
                setStyle({
                    top: (5) + 'vh',
                    right: rect.x + 'px',
                    width: (rect.width * .2) + 'px'
                })
            }
        }
    }, [setStyle, decor, parentRef]);

    useEffect(() => {
        window.addEventListener('scroll', update);
        window.addEventListener('resize', update);
        update();

        return () => {
            window.removeEventListener('scroll', update);
            window.removeEventListener('resize', update);
        }
    }, [update]);

    return <DecorStyled decor={decor} style={style}></DecorStyled>
}
