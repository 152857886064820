import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type Props = {
    question: string,
    questionId: string,
    answers: string[]
}

const Container = styled.div`



    fieldset {
        border: none;
        margin-top: 2em;



        legend {
            display: contents;
            ${props => props.theme.questionTextStyle}
        }

        label {
            padding: .5em;
            cursor: pointer;

            &:focus-within {
                outline: 1px dotted black;
            }

            .item {
                display: grid;
                grid-template-columns: 30px 1fr;
                gap: 10px;
            }

            span {
                display: inline-block;
                padding: .5em;
                border: 1px solid black;
            }

            input {
                opacity: 0;
                width: 0;
                height: 0;
                position: absolute;
            }

            .marker {
                width: 30px;
                height: 30px;
                border: 1px solid black;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                .x {
                    display: none;
                }
            }

            &:hover {
                .marker, span {
                    background: ${props => props.theme.accentColor}
                }
            }

            input:checked + .item {
                .marker, span {
                    background: ${props => props.theme.accentColor}
                }

                .marker {
                    .x {display: block}
                }
            }
        }
    }
`;

export default function SingleAnswerQuestion({ question, questionId, answers }: Props) {

    const { register, formState: { errors } } = useFormContext();

    return <Container>
        <fieldset>
            <legend>{question}</legend>
            {errors[questionId] && (
                <div><span className='error-info' role="alert">Proszę wybrać odpowiedź</span></div>
            )}
            {
                answers.map((text, index) => {
                    return <div key={index}>
                        <label>
                            <input
                                type="radio"
                                value={index}
                                aria-labelledby={`item-${index}`}
                                {...register(questionId, { required: true })}

                            />
                            <div className="item" id={`item-${index}`}>
                                <div className="marker"><div className="x" aria-hidden='true'>X</div></div>
                                <span>
                                    {text}
                                </span>
                            </div>
                        </label>
                    </div>
                })
            }
        </fieldset>
    </Container>
}
