import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";
import useFocusOnMount from "../../utils/use-focus-on-mount";
import imgSrc from './mail.png';



export default function Page4bMale() {

    const target = useFocusOnMount();

    return <PageLayout
        next={getGameRoute(PAGES.p5)}
        previous={getGameRoute(PAGES.p4a)}
        decors={DECOR_CONFIG.STORY_1}
    >

        <p ref={target}>W tej samej chwili komórka taty Kazika, Adama, lekko wibruje. <i>O nie</i>, myśli, <i>znowu jakiś spam</i>. A tu  zaskoczenie, wiadomość ze szkoły. Hmm…</p>

        <img src={imgSrc} alt="Drodzy Rodzice,
        przed nami ostatni rok wspólnej przygody, jaką jest edukacja Państwa dzieci. To wyjątkowy czas, w którym tylko współpracując osiągniemy sukces. Poniżej lista lektur i inne dokumenty organizacyjne. Proszę się z nimi zapoznać. Szczegóły omówimy na spotkaniu w najbliższy wtorek w sali nr 215 o godzinie 18:00.
        Pozdrawiam,
        mgr Bożena Kącka" />

        <p>
            Adam raczej nie cisnął Kazika zanadto, w końcu dzieciństwo to dzieciństwo. <i>Skoro wychowawczyni pisze o przygodzie, wyzwaniu, to czas się ogarnąć? Trzeba będzie trochę zmienić podejście, tak tak...</i> - zastanawia się Adam.
        </p>
        <p>
        Ostatnio rozmawiał z kolegami jak oni pracują ze swoimi dziećmi: o korkach, programie motywacyjnym z grywalizacją na portalu edukacyjnym i jakichś fajnych nagrodach. Wymagania podobno są ok, tylko nie można przesadzić. Jeden z kumpli nawet wprowadził codzienne wspólne ćwiczenia rano i wieczorem, dietę wysoko białkową i pomalował pokój na jasne kolory dla lepszej energii. Hmm…
        </p>
        <p>
        <i>Nie wiem, może trochę panikuję?</i> - myśli Adam.
        </p>
    </PageLayout>
}
