import styled from "styled-components";
import useGameProgress from "../../modules/main/hooks/use-game-progress";

const Container = styled.div<{progress: number}>`
    width: 100%;
    background: ${props => props.theme.accentColor};
    text-align: center;
    position: relative;

    span {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: ${({progress}) => (100 - progress)}%;

        background: rgb(118,255,100);;
    }
`;
export default function GameProgress() {
    const progress = useGameProgress();

    const value = (progress * 100) | 0;

    return <Container progress={value} role="progressbar" aria-live="polite" aria-label="Progres gry" aria-valuemin={0} aria-valuemax={100} aria-valuenow={value}>
        <span role="presentation">{value}%</span>
    </Container>
}
