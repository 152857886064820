import React from "react";
import styled from "styled-components";
import useFocusOnMount from "../../utils/use-focus-on-mount";
import Box from "../box";
import ColumnLayout, { Decor } from "../column-layout";
import NiceButton from "../nice-button";

type Props = {
    previous: string,
    previousLabel?: string,
    next: string,
    nextLabel?: string,
    focusNext?: boolean
    decors?: Decor[]
}

const Container = styled.main`
    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
`;

export default function PageLayout({ children, previous, previousLabel, next, nextLabel, decors, focusNext }: React.PropsWithChildren<Props>) {

    const target = useFocusOnMount(!focusNext)

    return <ColumnLayout
        left={<NiceButton to={previous} label={previousLabel || 'Wróć'} />}
        right={<NiceButton ref={target} to={next} label={nextLabel || 'Dalej'} />}
        decors={decors}
    >
        <Container>
            <Box>
                {children}
            </Box>
        </Container>
    </ColumnLayout>
}
