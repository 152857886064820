import styled from "styled-components";

import funduszeLogoSrc from '../../assets/logos/fundusze.png';
import polskaLogoSrc from '../../assets/logos/polska.png';
import popojutrzeLogoSrc from '../../assets/logos/popojutrze.png';
import senseLogoSrc from '../../assets/logos/sense.png';
import uniaLogoSrc from '../../assets/logos/unia.png';

const Container = styled.footer`
    background: white;

    .inner {
        max-width: 1520px;
        margin: 0 auto;
        height: 100%;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .item {
            flex: 0 0 18%;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                max-width: 100%;
            }
        }
    }
`;

export default function PageFooter() {
    return <Container>
        <div className="inner">
            <div className="item">
                <img src={funduszeLogoSrc} alt="" />
            </div>
            <div className="item">
                <img src={polskaLogoSrc} alt="" />
            </div>
            <div className="item">
                <img src={popojutrzeLogoSrc} alt="" />
            </div>
            <div className="item">
                <img src={uniaLogoSrc} alt="" />
            </div>
        </div>
    </Container>
}
