import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page42() {
    return <PageLayout
        previous={getGameRoute(PAGES.p41)}
        next={getGameRoute(PAGES.p44)}
        nextLabel={'Kolejna część'}
        decors={DECOR_CONFIG.OPEN_QUESTIONS}
    >
        <p>Rodzicu, Nastolatku, porozmawiajcie o tym, co poprawia Wam nastrój.</p>
        <p>Rodzicu, opowiedz o tym jak radziłeś sobie z trudnościami w nauce?</p>
        <p>Na czym polegały te trudności?</p>
    </PageLayout>
}
