import styled from "styled-components";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";


const Container = styled.div`

`;


export default function Topic({ id, topic, texts }: { id: string, topic: string, texts: string[] }) {
    const answer = useAnswer(id, PLAYER.PARENT);

    return <Container>
        <h3>{topic}</h3>
        <p>
            {texts[parseInt(answer)]}
        </p>
    </Container>
}
