import styled from "styled-components";
import Box from "../../components/box";
import NiceButton from "../../components/nice-button";
import { getGameStartRoute, getHomeRoute } from "../../routes/routes";

const Container = styled.main`
    max-width: 1440px;
    width: 95%;

    min-height: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    nav {
        margin-top: 5vh;
        display: flex;

        justify-content: space-around;
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: auto;
        max-width: 100%;
        display: inline-block;
    }
`;

export default function Page95() {
    return <Container>
        <Box>
            <p>Dziękujemy za wspólną podróż. Mamy nadzieję, że udało nam się zachęcić Was do dialogu w tym trudnym okresie, jakim jest kończenie szkoły podstawowej.</p>
            <p>Wierzymy, że nasza gra pozwoliła Wam lepiej zrozumieć emocje towarzyszące obu stronom w tym przełomowym momencie życia.</p>
            <p>Jeżeli macie dalszą potrzebę dyskusji, zajrzyjcie do zakładki “O czym i jak rozmawiać”.</p>
            <p style={{ textAlign: 'right', marginTop: '2em' }}>Powodzenia na egzaminie!</p>
            <p style={{ textAlign: 'right' }}>Twórcy</p>
        </Box>
        <nav>
            <NiceButton to={getGameStartRoute()} label={'Zagraj jeszcze raz'} />
            <NiceButton to={getHomeRoute()} label={'Strona główna'} />
        </nav>
    </Container>
}
