import ImageLayout2 from "../../components/image-layout-2";
import { getGameRoute, PAGES } from "../../routes/routes";
import imgSrc from './female.png';

export default function Page44Female() {
    return <ImageLayout2
        imgSrc={imgSrc}
        imgAlt={"Rozmowa na zoomie w której biorą udział Krysia, jej tata i pedagog szkolny. Pedagog informuje, że chciałby dowiedzieć się jak mogą, jako szkoła, pomóc Krysi w podjęciu decyzji."}
        previous={getGameRoute(PAGES.p43)}
        next={getGameRoute(PAGES.p45)}
    />
}
