import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page80Male() {

    const childAnswer: string[] = useAnswer('m-7-1', PLAYER.CHILD);

    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p79)}
        next={getGameRoute(PAGES.p81)}
        player={PLAYER.PARENT}
    >
        <p>Jaka reakcja na przyczyny słabego wyniku Kazika jest Twoim zdaniem odpowiednia?</p>
        {
            childAnswer.includes('0') && <SingleAnswerQuestion question={"Był niewyspany."} questionId={"m-7-2-0"} answers={[
                "Dlaczego się nie wyspałeś? Trzeba było się położyć wcześniej i nie grać tyle.",
                "Jakby można było cofnąć czas, to co byś zmienił w swoim postępowaniu?",
                "Zdarza się, następnym razem musisz się wcześniej położyć i porządnie wyspać.",
            ]} />
        }
        {
            childAnswer.includes('1') && <SingleAnswerQuestion question={"Zjadł go stres."} questionId={"m-7-2-1"} answers={[
                "No ale co Cię stresuje? Przecież nie można tego nie zdać!",
                "Inni też się tak stresowali?",
                "Chcesz mi powiedzieć, czym się stresowałeś? Masz pomysł jak mógłbyś się zrelaksować następnym razem?",
            ]} />
        }
        {
            childAnswer.includes('2') && <SingleAnswerQuestion question={"Nie mógł się skoncentrować."} questionId={"m-7-2-2"} answers={[
                "Ale to co tam się działo, że nie mogłeś się skoncentrować? Dyskoteka jakaś była?",
                "Nic nowego, jakoś nie zaskakuje mnie to.",
                "Ja też w takich sytuacjach mam problem z koncentracją. Chcesz posłuchać, jak staram się z tym sobie radzić?",
            ]} />
        }
        {
            childAnswer.includes('3') && <SingleAnswerQuestion question={"Nie przygotował się, nie powtórzył materiału."} questionId={"m-7-2-3"} answers={[
                "No nie, jak mogłeś nie powtórzyć tego?",
                "Trzeba naprawdę mieć wywalone, żeby tego się nie nauczyć.",
                "Rozumiem. Ciekaw jestem z czego wynika, że tego nie powtórzyłeś, powiesz mi?",
            ]} />
        }
        {
            childAnswer.includes('4') && <SingleAnswerQuestion question={"Miał pecha - same zadania akurat z tego z czego nie jest najlepszy."} questionId={"m-7-2-4"} answers={[
                "Na egzaminie nie pojawiło się nic, czego byście nie przerabiali w szkole. To nie jest wymówka.",
                "Dużo osób miało takiego pecha?",
                "Jak się z tym czujesz? Co planujesz zrobić teraz?",
            ]} />
        }
        {
            childAnswer.includes('5') && <SingleAnswerQuestion question={"To było dopiero pierwsze podejście."} questionId={"m-7-2-5"} answers={[
                "Ty to zawsze masz na wszystko dużo czasu. I wyszło jak zawsze.",
                "Jakoś mnie to w ogóle nie dziwi.",
                "Wiesz, właściwy egzamin też będzie Twoim pierwszym. Masz pomysły co zrobić w tej sytuacji?",
            ]} />
        }

    </SingleAnswerLayout>
}
