import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page36Male() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p35)}
        next={getGameRoute(PAGES.p37)}
        player={PLAYER.CHILD}>
        <SingleAnswerQuestion
            question={"W jaki sposób tata Kazika może mu pomóc? Wybierz najbliższą Ci odpowiedź."}
            questionId={'m-3-3'}
            answers={[
                "Obiecać super nagrodę za poprawienie wyników w nauce.",
                "Zakazać korzystania z komputera i smartfona, żeby zmusić Kazika do koncentracji na książkach i pracach domowych.",
                "Zagrozić, że jeżeli nie poprawi ocen, to nie wyjedzie na obóz z kolegami.",
                "Załatwić korepetycje.",
                "Zacząć wspólnie uczyć się z Kazikiem, poświęcić mu czas na wspólną naukę.",
                "Pozwolić Kazikowi samemu decydować o sobie, rozmawiając o konsekwencjach jego działań.",
                "Zostawić Kazika w spokoju."
            ]}
        />
    </SingleAnswerLayout>
}
