import { useMemo } from "react";
import PageLayout from "../../components/page-layout";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page54() {

    const answer = useAnswer('m-4-1', PLAYER.PARENT)

    const content = useMemo(() => {
        switch (answer) {
            case '0':
                return 'Jesteś w trybie działania. Pamiętaj, aby mobilizacja nie zmieniła się w presję.';

            case '1':
                return 'Jasne, w życiu ważna jest równowaga. Zastanów się jednak czy wynik egzaminu jest Ci na pewno obojętny? Jak może wpłynąć na dalsze wybory Twojego dziecka?'
        }
    }, [answer]);

    return <PageLayout
        previous={getGameRoute(PAGES.p53)}
        next={getGameRoute(PAGES.p55)}
    >
        {content}
    </PageLayout>
}
