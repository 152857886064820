import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page46() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p45)}
        next={getGameRoute(PAGES.p47)}
        player={PLAYER.CHILD}>
        <SingleAnswerQuestion
            question={"Jak podchodzisz do zbliżającego się egzaminu?"}
            questionId={"m-4-1"}
            answers={[
                'Wiem, że teraz muszę się zmobilizować, dać z siebie jak najwięcej. Oczywiście o innych sprawach też warto pamiętać.',
                'Na spokojnie. Nie da się nie zdać tego egzaminu. Ważne, żeby zadbać o równowagę i spokój.'
            ]}
        />
    </SingleAnswerLayout>
}
