import ImageLayout2 from "../../components/image-layout-2";
import { getGameRoute, PAGES } from "../../routes/routes";
import imgSrc from './img.png';

export default function Page61() {
    return <ImageLayout2
        imgSrc={imgSrc}
        imgAlt={"Rozmowa na czacie grupowym z przyjaciółmi. Sandra przypomina, że ich wychowawczyni zachęcała do wzięcia udziału w targach szkół."}
        previous={getGameRoute(PAGES.p60)}
        next={getGameRoute(PAGES.p62)}
    />
}
