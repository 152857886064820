import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page78Male() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p77)}
        next={getGameRoute(PAGES.p79)}
        player={PLAYER.CHILD}
    >
        <MultipleAnswerQuestion
            question={"Z jakiego powodu, Twoim zdaniem, Kazikowi nie poszedł próbny egzamin? (wskaż 3 najbardziej prawdopodobne Twoim zdaniem przyczyny)"}
            questionId={"m-7-1"}
            force={3}
            answers={[
                "Był niewyspany.",
                "Zjadł go stres.",
                "Nie mógł się skoncentrować.",
                "Nie przygotował się, nie powtórzył materiału.",
                "Miał pecha - same zadania akurat z tego, z czego nie jest najlepszy.",
                "To było dopiero pierwsze podejście.",
            ]}
        />

    </MultipleAnswerQuestionLayout>
}
