import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page55() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p54)}
        next={getGameRoute(PAGES.p56)}
        player={PLAYER.PARENT}>
        <SingleAnswerQuestion
            question={"Jak Twoim zdaniem należy motywować do nauki?"}
            questionId={"m-4-2"}
            answers={[
                'To ważny moment i trzeba zadbać o motywację. Z jednej strony warto zagwarantować nagrodę za dobry wynik egzaminu, a z drugiej powiedzieć też jakie będą konsekwencje nieuczenia się, np. zakaz spotkań z kolegami, zabranie telefonu.',
                'Żeby zadbać o motywację trzeba rozmawiać z dzieckiem, zrozumieć trudności jakie ma, pokazać mu konsekwencje decyzji i zachowań oraz dać autonomię w działaniu.'
            ]}
        />
    </SingleAnswerLayout>
}
