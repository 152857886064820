import { useCallback, useRef } from "react"
import { useDrop, DropTargetMonitor, XYCoord, useDrag } from "react-dnd"

const style = {
    cursor: 'grab',
    width: '100%'
}

export interface CardProps {
    id: any
    index: number,
    data: any
    moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
    index: number
    id: string
    type: string
}
export const Card: React.FC<CardProps> = ({ id, index, moveCard, data, children }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ draggedItem }, drop] = useDrop<DragItem, unknown, {draggedItem: DragItem}>({
        accept: 'item',
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
        collect: monitor => {
            return {
                draggedItem: monitor.getItem()
            }
        }
    })

    const getItem = useCallback(() => {
        return {
            id,
            index,
            type: 'item',
            data: data,
            size: {
                width: ref.current?.getBoundingClientRect().width,
                height: ref.current?.getBoundingClientRect().height
            }
        }
    }, [ref, data, id, index])

    const [, drag] = useDrag({
        item: () => getItem(),
        type: 'item'
    })

    const opacity = draggedItem?.id === id ? 0 : 1
    drag(drop(ref))
    return (
        <div ref={ref} style={{ ...style, opacity }}>
            {children}
        </div>
    )
}
