import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page34Female() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p33)}
        next={getGameRoute(PAGES.p35)}
        player={PLAYER.CHILD}
    >
        <MultipleAnswerQuestion
            question={"Jak Krysia może zadbać o lepszy nastrój? (wskaż maksymalnie 3 odpowiedzi)"}
            questionId={"m-3-1"}
            limit={3}
            answers={[
                "Zacząć regularnie uprawiać jakiś sport – uwalniające się endorfiny poprawiają nastrój.",
                "Ułożyć plan dnia – dzięki temu będzie wiedziała, co i kiedy powinna robić, nie nawarstwią jej się zaległości.",
                "Zadbać o sen, mniej niż 8 godzin snu powoduje obniżony nastrój.",
                "Zacząć się regularnie i zdrowo odżywiać. Jeśli jest głodna, to trudno się skupić i pogarsza się nastrój.",
                "Spotykać się ze znajomymi – naukowcy są zgodni, że dobre relacje z ludźmi dają nam szczęście.",
                "Ograniczyć czas przed ekranem komputera i smartfona – męczą wzrok i często pogarszają nastrój. ",
                "Zacząć próbować nowych aktywność, poszukać hobby, np. gra na instrumencie, albo włączenie się w wolontariat – dzięki temu będzie mogła się rozwijać i mieć poczucie sensu i satysfakcji."
            ]}
        />
    </MultipleAnswerQuestionLayout>
}
