import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page39Male() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p38)}
        next={getGameRoute(PAGES.p40)}
        player={PLAYER.PARENT}
    >
        <MultipleAnswerQuestion
            question={"Jak Kazik może rozwiązać problemy z nauką? (wskaż maksymalnie 3 odpowiedzi)"}
            questionId={"m-3-2"}
            limit={3}
            answers={[
                "Dostać wsparcie od rodzica.",
                "Zapisać się na dodatkowe zajęcia, korepetycje.",
                "Zacząć korzystać z zasobów Internetu  (khan acadmey, duolingo).",
                "Umówić się z kolegą/koleżanką na wspólną naukę.",
                "Porozmawiać o trudnościach  z nauczycielem.",
                "Olać sprawę i zobaczyć jak rozwinie się sytuacja.",
                "Robić ściągi.",
                "Ustalić plan dnia, zarezerwować czas na naukę i pamiętać, że najpierw nauka a potem przyjemności."
            ]}
        />
    </MultipleAnswerQuestionLayout>
}
