import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page63() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p62)}
        next={getGameRoute(PAGES.p64)}
        player={PLAYER.CHILD}
    >
        <SingleAnswerQuestion
            question={"Jaki rodzaj szkoły wybierasz?"}
            questionId={"m-5-1"}
            answers={[
                "Branżówkę – chcę mieć szybko konkretny zawód, mniej przedmiotów ogólnych, a więcej praktycznych, a maturę zawsze mogę zrobić jeśli pójdę do szkoły II stopnia.",
                "Technikum – wiem, że trwa dłużej niż liceum, ale kończę z uprawnieniami do konkretnego zawodu, no i spokojnie mogę podejść do matury a potem może pójść na studia.",
                "Liceum – cztery lata nauki, a potem matura. Akurat będę mieć czas, żeby dokładnie zastanowić się co robić później."
            ]} />
    </SingleAnswerLayout>
}
