import CompareAnswersLayout from "../../components/compare-answers-layout";
import { MODULE_4_QUESTIONS } from "../../modules/main/hooks/use-final-score";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page59() {
    return <CompareAnswersLayout
        previous={getGameRoute(PAGES.p58)}
        next={getGameRoute(PAGES.p60)}
        questions={MODULE_4_QUESTIONS}
    />
}
