import { useMemo } from "react";
import styled from "styled-components";
import { PLAYER } from "../../modules/main";
import useGame from "../../modules/main/hooks/use-game";
import useFocusOnMount from "../../utils/use-focus-on-mount";
import NiceButton from "../nice-button";

type Props = {
    previous: string,
    previousLabel?: string,
    next: string,
    nextLabel?: string,
    player: PLAYER
}

const Container = styled.main`
    max-width: 600px;
    width: 100%;

    min-height: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    nav {
        margin-top: 5vh;
        display: flex;

        justify-content: space-around;
    }

    .box {
        background: ${(props) => props.theme.boxBackground};
        min-height: 30vh;
        width: 100%;
        margin: 0 auto;

        display: flex;
            justify-content: center;
            align-items: center;


        .inner {
            padding: 2% 5%;
        }
    }
`;


export default function PlayerInfoLayout({ previous, previousLabel, next, nextLabel, player }: Props) {

    const target = useFocusOnMount(false, true);

    const { gameState } = useGame();
    const text = useMemo(() => {
        switch (player) {
            case PLAYER.CHILD:
                return 'Zaczyna ' + gameState.child.name;

            case PLAYER.PARENT:
                return 'Teraz odpowiada ' + gameState.parent.name;

            case PLAYER.BOTH:
                return 'Rozwiązujcie razem: ' + gameState.child.name + ' i ' + gameState.parent.name;

            default:
                return '';
        }
    }, [player, gameState]);

    return <Container>
        <div className="box">
            {text}
        </div>
        <nav>
            <NiceButton to={previous} label={previousLabel || 'Wróć'} />
            <NiceButton ref={target} to={next} label={nextLabel || 'Dalej'} />
        </nav>
    </Container>
}
