import { useState } from "react";
import styled from "styled-components";
import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";

type QuestionData = {
    id: string,
    text: string,
    answers: { id: string, text: string, correct?: boolean }[],
    info: string
}

const questions: QuestionData[] = [
    {
        id: 'q1',
        text: 'Jaki kolor ścian pomaga w nauce?',
        answers: [
            { id: 'a1', text: 'stonowany', correct: true },
            { id: 'a2', text: 'kolorowy' }
        ],
        info: 'Lepiej się uczy w przestrzeni o stonowanych kolorach. Łatwiej jest się skoncentrować. Zastanów się czy w czasie najbliższej przerwy od szkoły nie przemalować swojego pokoju, a może zmienić plakaty, obrazy wiszące na ścianie. '
    },
    {
        id: 'q2',
        text: 'Gdzie powinno stać biurko?',
        answers: [
            { id: 'a2', text: 'przy oknie' },
            { id: 'a1', text: 'w pewnej odległości od okna', correct: true },
        ],
        info: 'To co znajduje się za oknem może Cię rozpraszać (np. przejeżdżający samochód, bawiące się dzieci). Przesuń biurko, tak aby to co na zewnątrz nie przyciągało Twojego wzroku. Jednocześnie zadbaj o dostęp do okna - podczas przerw patrz daleko w niebo - to daje oczom odpoczynek. '
    },
    {
        id: 'q3',
        text: 'Co powinno znajdować się na biurku?',
        answers: [
            { id: 'a2', text: 'długopisy, ołówki i inne przybory, żeby były pod ręką' },
            { id: 'a1', text: 'tylko to co w danym momencie jest potrzebne', correct: true },
        ],
        info: 'Im mniej rozpraszaczy tym lepiej, a im więcej masz miejsca na zeszyt i książki tym zyskujesz większy komfort pracy. '
    },
    {
        id: 'q4',
        text: 'Z której strony powinno znajdować się źródło światła sztucznego?',
        answers: [
            { id: 'a2', text: 'dla praworęcznych z prawej,  dla leworęcznych  z lewej' },
            { id: 'a1', text: 'dla praworęcznych z lewej, dla leworęcznych z prawej', correct: true },
        ],
        info: 'Pamiętaj aby nie zasłaniać sobie dostępu do światła. Dzięki temu zadbasz o swój wzrok.'
    },
    {
        id: 'q5',
        text: 'Jak często powinno się robić przerwę od nauki?',
        answers: [
            { id: 'a2', text: 'co godzinę' },
            { id: 'a1', text: 'co 30 minut', correct: true },
        ],
        info: 'Badania dowodzą, że nasz umysł nie jest w stanie przez długi czas utrzymać koncentracji. Warto zaplanować regularne odstępy pracy.'
    },
    {
        id: 'q6',
        text: 'Ile powinna trwać taka przerwa?',
        answers: [
            { id: 'a2', text: '15 minut' },
            { id: 'a1', text: '7 minut', correct: true },
        ],
        info: 'Przerwy powinny być regularne i dość częste, ale nie za długie! W przeciwnym razie możesz potrzebować sporo czasu, żeby wrócić do tematu. '
    }
]

const Container = styled.div`

    input:checked + .answer-text.correct {
        color: green
    }

    input:checked + .answer-text.incorrect {
        color: red
    }
`;

export default function Page74() {
    return <PageLayout
        previous={getGameRoute(PAGES.p73)}
        next={getGameRoute(PAGES.p75)}
    >
        <Container>
            <p>Odpowiedzcie WSPÓLNIE na poniższe pytania:</p>
            {
                questions.map(q => {
                    return <Question key={q.id} data={q} />
                })
            }
        </Container>
    </PageLayout>
}

const QuestionContainer = styled.div`
    fieldset {
        border: none;
        margin-top: 2em;

        legend {
            ${props => props.theme.questionTextStyle}
        }

        label {
            padding: .5em;
            cursor: pointer;
            display: block;

            .item {
                display: grid;
                grid-template-columns: 1fr;
                gap: 10px;
            }

            span {
                display: inline-block;
                padding: .5em;
                border: 1px solid black;
            }

            input {
                display: block;
                opacity: 0;
                width: 0;
                height: 0;
            }

            .marker {
                width: 30px;
                height: 30px;
                border: 1px solid black;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                .x {
                    display: none;
                }
            }

            &:hover {
                span {
                    background: #FCD779
                }
            }

            input:checked + .item {
                span {
                    background: #FCD779
                }
                .answer-text.correct {
                    background: rgb(118, 255, 100)
                }

                .answer-text.incorrect {
                    background: rgb(255, 104, 104);
                }
            }

        }
    }
`;

function Question({ data }: { data: QuestionData }) {
    const [showInfo, setShowInfo] = useState(false);

    return <QuestionContainer>
        <fieldset>
            <legend>{data.text}</legend>
            {
                data.answers.map(a => {
                    return <div key={a.id}>
                        <label>
                            <input
                                type="radio"
                                name={data.id}
                                onChange={() => setShowInfo(true)}
                            />
                            <div className="item">
                                <span className={`answer-text ${a.correct ? 'correct' : 'incorrect'}`}>
                                    {a.text}
                                </span>
                            </div>
                        </label>
                    </div>
                })
            }
        </fieldset>
        {showInfo && <p role="status">
            {data.info}
        </p>}
    </QuestionContainer>
}
