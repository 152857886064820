import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page31() {
    return <PageLayout
            previous={getGameRoute(PAGES.p30)}
            next={getGameRoute(PAGES.p32)}
            nextLabel="Kolejna część"
            decors={DECOR_CONFIG.OPEN_QUESTIONS}
            >
        <p>
        Rodzicu, opowiedz jak wyglądała Twoja edukacja.
        </p>
        <p>
        Na jakie zajęcia dodatkowe chodziłeś? 
        </p>
        <p>
        Co Cię interesowało w młodości?
        </p>
        <p>
        Jakie miałeś wyniki w nauce?
        </p>
        <p>
        A może spróbujcie znaleźć Twoje świadectwo ukończenia ósmej klasy?
        </p>
    </PageLayout>
}
