import PlayerInfoLayout from "../../components/player-info-layout";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page86() {
    return <PlayerInfoLayout
        previous={getGameRoute(PAGES.p85)}
        next={getGameRoute(PAGES.p87)}
        player={PLAYER.PARENT}
    />
}
