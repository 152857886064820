import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page90() {
    return <PageLayout previous={getGameRoute(PAGES.p89)} next={getGameRoute(PAGES.p91)} nextLabel={'Kolejna część'}
    decors={DECOR_CONFIG.OPEN_QUESTIONS}>
        <p>Rodzicu, opowiedz jak radziłeś sobie przed egzaminami.</p>
        <p>Co usłyszałeś przed egzaminami od swoich rodziców (wskaż zarówno dobre jak i złe rzeczy)?</p>
        <p>Jakie były wyniki Twoich egzaminów?</p>
    </PageLayout>
}
