import PlayerInfoLayout from "../../components/player-info-layout";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page65() {
    return <PlayerInfoLayout
        previous={getGameRoute(PAGES.p64)}
        next={getGameRoute(PAGES.p66)}
        player={PLAYER.PARENT}
    />
}
