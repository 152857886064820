import { useMemo } from "react";
import styled from "styled-components";
import { HEADS } from "../../assets/heads";
import { CHARACTER_GENDER } from "../../modules/main";
import useGame from "../../modules/main/hooks/use-game";
import useScore from "../../modules/main/hooks/use-score";
import useFocusOnMount from "../../utils/use-focus-on-mount";
import NiceButton from "../nice-button";

type Props = {
    previous: string,
    previousLabel?: string,
    next: string,
    nextLabel?: string,
    questions: string[]
}

const Container = styled.main<{ char: CHARACTER_GENDER }>`
    max-width: 1440px;
    width: 100%;

    min-height: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    background:
        url(${props => props.char === CHARACTER_GENDER.MALE ? HEADS.KAZIK : HEADS.KRYSIA}) 0 50% no-repeat,
        url(${HEADS.ADAM}) right 50% no-repeat;
    background-size: contain;

    nav {
        display: flex;

        max-width: 600px;
        width: 100%;
        margin: 5vh auto;

        justify-content: space-around;
    }

    .box {
        background: ${(props) => props.theme.boxBackground};
        min-height: 30vh;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;

        display: flex;
            justify-content: center;
            align-items: center;


        .inner {
            padding: 2% 5%;
        }
    }

    .result {
        display: inline-block;
        font-weight: bold;
        font-size: 2em;
        margin: .5em 0;
    }
`;

export default function CompareAnswersLayout({ previous, previousLabel, next, nextLabel, questions }: Props) {

    const { gameState } = useGame();
    const value = useScore(questions);

    const target = useFocusOnMount(false, true);

    const scoretext = useMemo(() => {
        if (value <= 30) {
            return 'Macie odmienne punkty widzenia na wiele spraw. Spróbujcie znaleźć tematy, które Was łączyły i sprawdźcie w czym się różniliście.';
        }
        else if (value > 30 && value <= 49) {
            return "Sporo Was różni. Porozmawiajcie o tym w czym jesteście podobni a na co patrzycie inaczej.";
        }
        else if (value > 49 && value <= 75) {
            return "Całkiem sporo Was łączy. Porozmawiajcie o tym w czym byliście zgodni a w czym się różniliście.";
        }
        else if (value > 75 && value <= 99) {
            return "Na wiele tematów patrzycie tak samo. Porozmawiajcie o tym co Was różniło.";
        }
        else if (value >= 100) {
            return "Macie identyczne spojrzenie na edukację. Stawcie czoła kolejnym wyzwaniom!";
        }
    }, [value]);

    return <Container char={gameState.character}>
        <div className="box">
            <div className="inner">
                <div>
                    {/* <table>
                        <thead>
                            <tr>
                                <td>Question</td>
                                <td>Dzieciak</td>
                                <td>Rodzic</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                questions.map(q => {


                                    return <tr key={q}>
                                        <td>{q}</td>
                                        <td>{gameState.child.answers.find(a => a.id === q)?.value}</td>
                                        <td>{gameState.parent.answers.find(a => a.id === q)?.value}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table> */}
                </div>
                Wasze odpowiedzi są<br /><span className="result"> w {value}%</span><br /> takie same.<br /><br />
                {scoretext}
            </div>
        </div>
        <nav>
            <NiceButton to={previous} label={previousLabel || 'Wróć'} />
            <NiceButton ref={target} to={next} label={nextLabel || 'Dalej'} />
        </nav>
    </Container>
}
