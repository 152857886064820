import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page76Male() {
    return <PageLayout
        previous={getGameRoute(PAGES.p75)}
        next={getGameRoute(PAGES.p77)}
        decors={DECOR_CONFIG.STORY_4_MALE}
    >
        <p>Adam otrzymał właśnie wiadomość od wychowawczyni Kazika. Niestety wyniki próbnego egzaminu syna są poniżej jego własnych przewidywań. To sygnał ostrzegawczy a czasu na reakcję jest mało. Pomóżcie Adamowi i Kazikowi!</p>
    </PageLayout>
}
