import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type Props = {
    question: string,
    questionId: string,
    infoText: string,
    correct: boolean
}

const Container = styled.div`
    fieldset {
        border: none;
        margin-top: 2em;



        legend {
            ${props => props.theme.questionTextStyle};
            margin-bottom: 1em;
        }

        label {
            padding: .5em;
            cursor: pointer;

            &:focus-within {
                outline: 1px dotted black;
            }

            .item {
                display: flex;
                justify-content: center;
            }

            span {
                display: inline-block;
                padding: .5em;
                border: 1px solid black;
                border-radius: .5em;
            }

            input {
                opacity: 0;
                width: 0;
                height: 0;
                position: absolute;
            }

            &:hover {
                span {
                    background: #FCD779
                }
            }

            input:checked + .item {
                span {
                    background: rgb(255, 104, 104);
                }

                &.correct span{
                    background: rgb(118, 255, 100)
                }
            }
        }

        .answers {
            max-width: 600px;
            margin: 0 auto;

            display: grid;
            grid-template-columns: 1fr 1fr
        }
    }
`;

export default function TrueFalseQuestion({ question, questionId, correct, infoText }: Props) {

    const { register, watch, formState: { errors } } = useFormContext();

    const value = watch(questionId, null);

    return <Container>
        <fieldset>
            <legend>{question}</legend>
            <div className="answers">
                <div>
                    <label>
                        <input type="radio" value={1} {...register(questionId, { required: true })} />
                        <div className={`item ${correct ? 'correct' : ''} `}>
                            <span>
                                TAK
                            </span>
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        <input type="radio" value={0} {...register(questionId, { required: true })} />
                        <div className={`item ${!correct ? 'correct' : ''} `}>
                            <span>
                                NIE
                            </span>
                        </div>
                    </label>
                </div>
            </div>
        </fieldset>
        {
            ((value !== null) && value !== (correct ? '1' : '0')) && <p role="status">
                {infoText}
            </p>
        }
        {errors[questionId] && (
            <span className='error-info' role="alert">Proszę wybrać odpowiedź</span>
        )}
    </Container>
}
