import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page11Female() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p10)}
        next={getGameRoute(PAGES.p12)}
        player={PLAYER.PARENT}
    >
        <SingleAnswerQuestion
            question={"Kto, Twoim zdaniem, odpowiada za wyniki w nauce?"}
            questionId={"m-1-2"}
            answers={[
                "Wyłącznie uczennica – to ona zdobywa wiedzę i umiejętności.",
                "Wyłącznie rodzic – powinien stworzyć takie warunki, aby dziecko się nauczyło.",
                "Wspólnie uczennica i rodzic – nauka to wspólny proces przez który powinni przechodzić razem aż do matury.",
                "Nauczyciel – powinien zrobić wszystko, żeby uczennica się nauczyła."
            ]}
        />
    </SingleAnswerLayout >
}
