import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page19Male() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p18)}
        next={getGameRoute(PAGES.p20)}
        player={PLAYER.CHILD}
    >
        <MultipleAnswerQuestion
            question={"Jak myślisz, jaki może być powód niskiej frekwencji Kazika? (wybierz maksymalnie 2)"}
            questionId={"m-2-3"}
            limit={2}
            answers={[
                "Był chory.",
                "Nie przychodził ze względu na bóle psychosomatyczne wywołane stresem przed szkołą.",
                "W tym czasie uczył się do bardziej wymagających przedmiotów.",
                "Wagarował.",
                "Nie czuje się komfortowo z rówieśnikami z klasy.",
            ]}
        />
    </MultipleAnswerQuestionLayout>
}
