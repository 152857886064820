import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page20Male() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p19)}
        next={getGameRoute(PAGES.p21)}
        player={PLAYER.CHILD}
    >
        <MultipleAnswerQuestion
            question={"Jak myślisz, jaki może być powód obniżenia wyników w nauce? (wybierz maksymalnie 2)"}
            questionId={"m-2-4"}
            limit={2}
            answers={[
                "Bo się nie uczy.",
                "Poświęca dużo czasu na naukę, ale nie może się skoncentrować.",
                "Nie widzi sensu nauki niektórych przedmiotów.",
                "Bo ma duże zaległości, które się nawarstwiły.",
                "Woli rozwijać pasje pozaszkolne.",
                "Nauczyciele są na niego “cięci”, niesprawiedliwie oceniają Kazimierza.",
                "Koleżanki i koledzy dokuczali mu z powodu wysokich ocen.",
                "Woli czas poświęcić przyjaciołom, znajomym.",
            ]}
        />
    </MultipleAnswerQuestionLayout>
}
