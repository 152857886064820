import OrderQuestionLayout from "../../components/order-question-layout.tsx";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page18Female() {
    return <OrderQuestionLayout
        previous={getGameRoute(PAGES.p18)}
        next={getGameRoute(PAGES.p19)}
        questionId='m-2-2'
        player={PLAYER.CHILD}
        instruction="Jak myślisz, co powinno być najważniejsze dla Krysi w tym roku? Ustaw wartości od najważniejszej do najmniej istotnej."
        items={[
            "Wysoki wynik egzaminu kończącego szkołę podstawową.",
            "Rozwijanie pasji/hobby.",
            "Komfort psychiczny.",
            "Akceptacja i uznanie kolegów i koleżanek.",
            "Wypoczynek.",
            "Rozrywka.",
            "Poczucie niezależności.",
        ]}
    />
}
