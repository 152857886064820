import CompareAnswersLayout from "../../components/compare-answers-layout";
import { MODULE_5_QUESTIONS } from "../../modules/main/hooks/use-final-score";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page68() {
    return <CompareAnswersLayout
        previous={getGameRoute(PAGES.p67)}
        next={getGameRoute(PAGES.p69)}
        questions={MODULE_5_QUESTIONS}
    />
}
