import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page53() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p52)}
        next={getGameRoute(PAGES.p54)}
        player={PLAYER.PARENT}>
        <SingleAnswerQuestion
            question={"Jak podchodzisz do zbliżającego się egzaminu?"}
            questionId={"m-4-1"}
            answers={[
                'Teraz czas na mobilizację, trzeba dać z siebie jak najwięcej, oczywiście zadbać o inne sprawy też warto.',
                'Nie przesadzajmy z tą 8 klasą i egzaminem. Nie da się tego nie zdać. Ważne, żeby zadbać o równowagę i spokój.'
            ]}
        />
    </SingleAnswerLayout>
}
