import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page22Female() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p21)}
        next={getGameRoute(PAGES.p23)}
        player={PLAYER.CHILD}
    >
        <SingleAnswerQuestion
            question={"W jakim stopniu zdobycie wykształcenia jest dla Ciebie ważne?"}
            questionId={"m-2-6"}
            answers={[
                "Bardzo ważne, dzięki niemu będę mogła znaleźć dobrą pracę i dobrze zarabiać.",
                "Ważne, wykształcenie pomaga w życiu, daje możliwości.",
                "Średnio ważne, dobrze jest mieć wykształcenie, ale są rzeczy ważniejsze, na których też warto się skoncentrować.",
                "Mało ważne, wykształcenie niczego nie przesądza.",
                "W ogóle nie jest istotne, wykształcenie nie jest mi potrzebne.",
            ]} />
    </SingleAnswerLayout>
}
