import styled from "styled-components";
import Box from "../../components/box";
import PageFooter from "../../components/footer";
import NiceButton from "../../components/nice-button";
import { getHomeRoute } from "../routes";

const Container = styled.div`
min-height: 100vh;
width: 100%;

display: flex;
flex-direction: column;
align-items: stretch;

main {
    width: 95%;
    max-width: 1520px;
    margin: 0 auto;
    flex: 1 1 auto;
    position: relative;
    padding: 5vh 0;

    display: flex;
    align-items: center;

    section {
        margin: 0 0 3em;
    }

    h4 {
        margin-top: 2em;
    }

    .wrapper {
        display: grid;
        grid-template-columns: 15% 85%;
        gap: .5em
    }

    nav {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    a{
        text-decoration:none;
    }
    .nav-item.item-2{
        padding-top:1.5em;
    }

    .document-link {
        margin: 1em 0;
        padding: 1em;
        border: solid 2px black;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: ${props => props.theme.accentColor};
        font-size: 1.1em;

        span {
            font-weight: bold;
            display: inline-block;
            font-size: 1.2em;
            margin-bottom: .5em;
        }
    }
}

footer {
    background: white;
    flex: 0 0 100px;
}

ul {
    margin: .7em .7em .7em 2em;
    line-height: 1.5em;
}

.challange {
    font-style: italic;
}
div>video{
    width: 100%;
    padding-top: 5%;
}
`;

export default function KnowledgePage() {
    return <Container>
        <main>
            <div className="wrapper">

                <nav>
                    <NiceButton to={getHomeRoute()} label="Wróć" />
                </nav>
                <Box>
                    <h2>O czym i jak rozmawiać?</h2>
                    <section>

                        <h3>Drodzy Rodzice,</h3>
                        <p>
                            zachęcamy Was do częstych rozmów z młodymi. W ten sposób dowiecie się  więcej o sobie i lepiej będziecie mogli się zrozumieć.
                            Poniżej znajdziecie pytania, które mogą zainspirować Was do rozpoczęcia  konwersacji z nastolatkiem. Ważne, abyście uważnie słuchali odpowiedzi młodych ale także mówili o swoich potrzebach, doświadczeniach, odczuciach, opiniach.
                        </p>
                        <p>
                            Sugerujemy, abyście:
                        </p>
                        <ul>
                            <li>mówili w swoim imieniu, a nie uogólniali sądów (np. zamiast wszyscy tak myślą - ja tak uważam)</li>
                            <li>nie oceniali (np. to nieodpowiedzialne, jesteś leniwy)</li>
                            <li>nie zaprzeczali emocjom drugiej strony, tylko lepiej je poznali (np. zamiast - nie ma co się smucić - jak myślisz, z czego wynika ten smutek)</li>
                            <li>zakładali dobre intencje drugiej strony</li>
                            <li>nie używali ironii i sarkazmu, kiedy rozmawiacie o ważnych dla siebie lub partnera sprawach</li>
                            <li>naprawdę uważnie słuchali, możecie używać parfrazy (np. jeśli dobrze Cię rozumiem, to uważasz, że…; Rozumiem, że chodzi ci o to, że…)</li>
                            <li>nie przerywali, pozwólcie skończyć myśl rozmówcy</li>
                        </ul>
                        <div className="document-link">
                            <span>Pobierz</span> <a href="/assets/Baza_wiedzy_Educhoice.pdf" download="Baza_wiedzy_Educhoice.pdf">Baza wiedzy. Pytania i odpowiedzi.  </a>
                        </div>
                    </section>
                    <section>

                        <h3>Tematy do rozmowy</h3>
                        <h4>Moduł I O odpowiedzialności i nastroju</h4>
                        <p>Jak się czujesz w związku z końcem szkoły podstawowej?</p>
                        <p>Jak radzisz sobie w tej sytuacji?</p>
                        <p>Kto powinien podejmować decyzje dotyczące wyboru szkoły ponadpodstawowej? Dlaczego?</p>
                        <p>Jak mogę  Cię wesprzeć na koniec szkoły podstawowej?</p>

                        <h4>Moduł II Oczekiwania i wartości</h4>
                        <p>Jakie masz marzenia? Co chciałbyś/chciałabyś osiągnąć?</p>
                        <p>W jakim stopniu te marzenia są realne?</p>
                        <p>Co jest dla Ciebie ważne w życiu?</p>
                        <p>Co jest ważne w związku ze zmianą szkoły?</p>
                        <p>Jakie oczekiwania masz wobec mnie  w związku z końcem szkoły podstawowej?</p>
                        <p>Jaka średnia na koniec klasy 8 Cię satysfakcjonuje? Jakiego wyniku na egzaminie 8 klasisty się spodziewasz?</p>
                        <p>Co Cię interesuje?</p>
                        <p className="challange">Odpowiedz o tym:</p>
                        <ul className="challange">
                            <li>na jakie zajęcia chodziłeś, co Cię interesowało w młodości</li>
                            <li>jakie wyniki miałeś na egzaminach</li>
                        </ul>
                        <p className="challange">Wyzwanie: Znajdźcie świadectwo rodzica z 8 klasy. Poznaj historię rodzica związaną z edukacją, której jeszcze nie znasz.</p>

                        <h4>Moduł III Motywacja</h4>
                        <p>Co Ci poprawia nastrój?</p>
                        <p>Opowiedz jak radziłeś sobie z trudnościami w nauce w szkole. Jakie to były trudności?</p>
                        <p>Co zachęca Cię do działania, uczenia się nowych rzeczy?</p>
                        <p>Co Cię zniechęca do uczenia się nowych rzeczy?</p>

                        <h4>Moduł IV Przekonania</h4>
                        <p>Kiedy ostatnio czułeś się doceniony?</p>
                        <p className="challange">Pomyślcie, za co się doceniacie. Powiedzcie to sobie. Możecie też napisać na kartkach i się nimi wymienić.</p>
                        <p className="challange">Wyzwanie: W najbliższy weekend skupcie się na docenianiu się. Bądźcie uważni i jak tylko uznacie, że druga strona zrobiła coś wartego docenienia, nia wahajcie się i powiedzcie jej o tym.</p>

                        <h4>Moduł V Kryteria wyboru szkoły</h4>
                        <p>O wyborze jakiej szkoły myślisz (branżówka, technikum, liceum) ? Dlaczego takiej?</p>
                        <p>Co dla Ciebie jest ważne przy wyborze szkoły?</p>
                        <p className="challange">Opowiedz:</p>
                        <ul className="chellange">
                            <li>Co  myślisz o wyborach dziecka?</li>
                            <li>Do jakiej szkoły chodziłeś?</li>
                            <li>Jak to się stało, że właśnie do takiej? Jak ją oceniasz?</li>
                            <li>Co dla Ciebie było ważne w szkole ponadpodstawowej?</li>
                        </ul>

                        <h4>Moduł VI Organizacja pracy</h4>
                        <p className="challange">Opowiedz:</p>
                        <ul className="chellange">
                            <li>Z jakimi trudnościami w organizacji pracy się borykasz?</li>
                            <li>Które z nich wynikają z Twoich postaw, a które są od Ciebie niezależne?</li>
                            <li>Jak sobie z nimi radzisz? Czy zawsze to działa?</li>
                            <li>Z jakich narzędzi planowania korzystasz? Które z nich poleciłbyś dziecku?</li>
                        </ul>

                        <p className="challange">Wyzwanie: Przez tydzień na koniec dnia wspólnie sprawdzajcie swoje checklisty i planujcie zadania na kolejny dzień.</p>

                        <h4>Moduł VII Porażka</h4>
                        <p>Jak się czujesz kiedy popełnisz błąd?</p>
                        <p>Jakie błędy ostatnio popełniłeś? Jak sobie z nimi poradziłeś?</p>
                        <p className="challange">Odpowiedz:</p>
                        <ul className="challange">
                            <li>Jakie błędy popełniłeś  w młodości? Jak sobie z nimi  poradziłeś?</li>
                            <li>Czy zgadzasz się, że porażki, popełnione błędy obniżają poczucie własnej wartości? Co Twoim zdaniem można zrobić, żeby błąd nie obniżał poczucia własnej wartości?</li>
                        </ul>

                        <h4>Moduł VIII Strategie przed i w trakcie egzaminu</h4>
                        <p>Jak radzisz sobie przed ważnymi sprawdzianami?</p>
                        <p className="challange">Opowiedz:</p>
                        <ul className="challange">
                            <li>Jak radziłeś sobie w trakcie egzaminów?</li>
                            <li>Jakimi wskazówkami mógłbyś się podzielić?</li>
                            <li>Jakie miałeś wyniki egzaminów?</li>
                            <li>Co usłyszałeś najgorszego/najlepszego od swoich rodziców?</li>
                        </ul>

                        <h4>Modul IX Wyniki i wybór szkoły</h4>
                        <p>Jak chciałbyś dowiedzieć się o wynikach egzaminu?</p>
                        <p>Jak wyobrażasz sobie ten moment?</p>
                        <p>Jak chciałbyś, żebym zareagował  na Twój wynik?</p>
                        <p>Jaki masz pomysł na wspólną celebrację końca nauki w szkole podstawowej?</p>
                        <p>Jak świętować nawet przy wynikach poniżej oczekiwań?</p>
                        <p>Jaką przyjąć strategię wyboru szkół? Czy znasz zasady rekrutacji do szkół?</p>
                    </section>

                </Box>
            </div>
        </main>
        <PageFooter />
    </Container>
}
