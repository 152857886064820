import TrueFalseLayout from "../../components/true-false-layout";
import TrueFalseQuestion from "../../components/true-false-layout/true-false-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page72Female() {
    return <TrueFalseLayout
        previous={getGameRoute(PAGES.p71)}
        next={getGameRoute(PAGES.p73)}
        player={PLAYER.BOTH}
    >
        <p>Cel SMART, to cel który jest konkretny, mierzalny, osiągalny, realny, określony w czasie. </p>
        <p>Wskaż, które cele spełniają te kryteria:</p>
        <TrueFalseQuestion
            question={"Zostanę laureatką olimpiady z przedsiębiorczości."}
            questionId={"q1"}
            infoText={"Nie jest wskazane kiedy chcesz to zrobić, poza tym ta olimpiada jest dla uczniów szkół ponadpodstawowych."}
            correct={false} />
        <TrueFalseQuestion
            question={"W ciągu miesiąca pójdę z tatą do parku trampolin."}
            questionId={"q2"}
            infoText={"Ten cel jest konkretny, określony w czasie, możliwy do wykonania."}
            correct={true} />
        <TrueFalseQuestion
            question={"Przez tydzień każdego dnia będę korzystała ze smartfona nie dłużej niż godzinę."}
            questionId={"q3"}
            infoText={"Ten cel jest konkretny, określony w czasie, możliwy do wykonania."}
            correct={true} />
        <TrueFalseQuestion
            question={"W przyszłym miesiącu poprawię matematykę."}
            questionId={"q4"}
            infoText={"Nie wiadomo do kiedy chcesz to zrobić i co dokładnie chcesz poprawić."}
            correct={false} />
    </TrueFalseLayout>
}
