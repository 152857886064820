import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page24Female() {
    return <SingleAnswerLayout previous={getGameRoute(PAGES.p23)} next={getGameRoute(PAGES.p25)} player={PLAYER.PARENT}>
        <SingleAnswerQuestion
            question={"Jak oceniasz wyniki Krysi?"}
            questionId={"m-2-1"}
            answers={[
                "Bardzo dobre.",
                "Dobre.",
                "Niezłe.",
                "Słabe.",
                "Bardzo słabe."
            ]}
        />
    </SingleAnswerLayout>
}
