import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page93() {
    return <PageLayout previous={getGameRoute(PAGES.p92)} next={getGameRoute(PAGES.p94)}
        decors={DECOR_CONFIG.OPEN_QUESTIONS}
    >
        <p>Rodzicu, Nastolatku, a jak Wy się zachowacie jeśli wynik egzaminu okaże się poniżej waszych oczekiwań?</p>
        <p>A jeżeli wynik będzie wyższy? Czym będą różniły się te reakcje?</p>
    </PageLayout>
}
