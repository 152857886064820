import ImageLayout2 from "../../components/image-layout-2";
import { getGameRoute, PAGES } from "../../routes/routes";
import imgSrc from './img.png';

export default function Page32Male() {
    return <ImageLayout2
        imgSrc={imgSrc}
        imgAlt={"Rozmowa na czacie z Tadzikiem. Kazik skończył właśnie egzamin próbny i jest zdołowany. Tadzik próbuje pocieszyć kolegę, ale nie potrafi się utożsamić z jego problemem. Nasz bohater zastanawia się co zrobić ale nie dochodzi do żadnej konkluzji."}
        previous={getGameRoute(PAGES.p31)}
        next={getGameRoute(PAGES.p33)}
        description={"Kazik po próbnych egzaminach pisze do Tadzika."}
    />
}
