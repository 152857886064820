export function getHomeRoute() {
    return '/'
}

export function getGameStartRoute() {
    return '/poczatek-gry'
}

export function getLetsTalkRoute() {
    return '/o-innowatorach'
}

export function getBeRoute() {
    return '/dla-nauczyciela'
}
export function GetIntroRoute() {
    return '/intro'
}

export function GetKnowledgeRoute(){
    return '/baza-wiedzy'
}

export const PAGES: { [key: string]: string } = (() => {
    const result: { [key: string]: string } = {};

    for (let i = 3; i <= 95; i++) {
        result['p' + i] = `${i}`;
    }
    return{
        ...result,
        p4a : "4a",
        p4b : "4b"
    }
})();

export function getGameRoute(page = ':page') {
    return `/game/${page}`;
}
