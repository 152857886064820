import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page84Male() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p83)}
        next={getGameRoute(PAGES.p85)}
        player={PLAYER.CHILD}
    >
        <SingleAnswerQuestion
            question={"Jakie zdanie najbardziej chciałbyś usłyszeć od rodzica przed egzaminem? (Wybierz jedną odpowiedź)"}
            questionId={"m-8-1"}
            answers={[
                "Chłopaki nie płaczą, weź się w garść i będzie dobrze.",
                "Spoko, loko młody, dasz radę.",
                "Nie przejmuj się, najwyżej zawalisz, ale świat się nie zawali.",
                "Wierzę w Ciebie.",
                "Wiem, że to trudny moment. Będę myślami z Tobą.",
                "Pamiętam jak ja denerwowałem się przed  egzaminem. Domyślam się, że Tobie też musi być trudno. ",
                "Widzę, że się denerwujesz. Czy mogę Ci jakoś pomóc? Wiedz, że bardzo Cię kocham.",
            ]}
        />
    </SingleAnswerLayout>
}
