import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page76Female() {
    return <PageLayout
        previous={getGameRoute(PAGES.p75)}
        next={getGameRoute(PAGES.p77)}
        decors={DECOR_CONFIG.STORY_4_FEMALE}
    >
        <p>Adam otrzymał właśnie wiadomość od wychowawczyni Krysi. Niestety wyniki próbnego egzaminu córki są poniżej jej własnych przewidywań. To sygnał ostrzegawczy a czasu na reakcję jest mało. Pomóżcie Adamowi i Krysi!</p>
    </PageLayout>
}
