import PageLayout from "../../components/page-layout";
import StoryInfo from "../../components/story-info";
import { getGameRoute, getGameStartRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";
import useFocusOnMount from "../../utils/use-focus-on-mount";



export default function Page4aMale() {

    const target = useFocusOnMount();

    return <PageLayout
        next={getGameRoute(PAGES.p4b)}
        previous={getGameStartRoute()}
        decors={DECOR_CONFIG.STORY_1}
    >

        <StoryInfo>
            <dl>
                <dt className="bigger">Bohaterowie:</dt>
                <dd>
                    <ul>
                        <li>Kazik, lat 15, syn Adama i Ewy, chodzi do klasy 8c razem z Tadkiem i Sandrą.</li>
                        <li>Bożena Kącka – wychowawczyni 8c, nauczycielka geografii, zwana przez uczniów BiKej.</li>
                    </ul>
                </dd>
                <dt>Miejsce:</dt>
                <dd>Szkoła podstawowa</dd>
                <dt>Czas:</dt>
                <dd>Pierwszy dzień ostatniej klasy szkoły podstawowej</dd>
            </dl>
        </StoryInfo>

        <p ref={target}>Kazik zmierza do sali. Po drodze spotyka swoich przyjaciół - Sandrę i Tadka. Nasi bohaterowie przychodzą spóźnieni o czym BiKej, oczywiście, musi napomknąć. Świetny początek.</p>

        <p className='talk-phase'> Ten rok dla nas wszystkich, moje słoneczka, będzie wyjątkowy. Wyfruniecie z gniazdka w świat. Trzeba będzie się postarać, egzamin to nie dowcip. A teraz opowiem trochę o planie zajęć.</p>

        <p>Po pół godzinie Kazik wraz z Sandrą i Tadkiem siadają na murku przed szkołą.</p>

        <p className='talk-phase'> Ile tych lekcji? Do tego jeszcze korki i pozamiatane - Kazik teatralnie pociera czoło.</p>
        <p className='talk-phase'> Ja będę codziennie cisnął repetytoria po lekcjach, mam plan i nie zawaham się go wdrożyć - twierdzi stanowczo Tadeusz, który zawsze lubił tworzyć strategie i plany a potem skrupulatnie je wdrażać.</p>
        <p className='talk-phase'> A ja leszcze wrzucam na luz, bo widziałam shorta o tym, że stres niszczy pamięć. Mózg wypala, zostaje taka breja. Ogólnie to trzeba na ostatniej prostej pocisnąć i będzie dobrze - Sandra robi piruet i ląduje na plecaku Tadka.</p>
        <p className='talk-phase'> Tak serio… Co ja mam zrobić? - pyta lekko spanikowany Kazik.</p>


    </PageLayout>
}
