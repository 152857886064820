import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page82Male() {
    return <PageLayout
        previous={getGameRoute(PAGES.p81)}
        next={getGameRoute(PAGES.p83)}
        decors={DECOR_CONFIG.STORY_5_MALE}
    >
        <p>Auto wchodzi w zakręt i z piskiem opon mknie ulicą Szkolną. W środku nasi bohaterowie: Kazik i Adam i stosy notatek przewalające się po kabinie auta.</p>
        <p className='talk-phase'> Pamiętaj synek, mamy jeszcze pół godziny a pierwszym królem Polski był…? - pyta Adam.</p>
        <p className='talk-phase'> To czemu tak pędzimy? Mieszko? A nie Chrobry, Chrobry tak tak. Możemy wolniej? - prosi zdenerwowany Kazik.</p>
        <p className='talk-phase'> Tak, już zwalniam, może jeszcze staniemy na chwilę i meliskę wypijemy? Mama zrobiła - proponuje Adam, równie zestresowany co syn.</p>
        <p>Kazik przez szybę auta zauważa Tadka i Sandrę, którzy właśnie wysiedli z autobusu.</p>
        <p className='talk-phase'> O, to może ja już z nimi pójdę - proponuje nieśmiało Kazik, który jak najszybciej chce mieć ten egzamin za sobą.</p>
        <p className='talk-phase'> Dobra, ale pamiętaj…! - zaczyna Adam</p>
        <p className='talk-phase'> O czym? -  pyta lekko zirytowany Kazik.</p>
        <p className='talk-phase'> No pamiętaj…</p>
        <p>Co, Twoim zdaniem, Adam powinien powiedzieć Kazikowi przed egzaminem?</p>

    </PageLayout>
}
