export interface PageTheme {
    boxBackground: string;
    accentColor: string;

    questionTextStyle: string;
}

export const defaultTheme: PageTheme = {
    boxBackground: '#FAF5E8',
    accentColor: '#FCD779',

    questionTextStyle: `
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
    `
}
