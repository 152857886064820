import PlayerInfoLayout from "../../components/player-info-layout";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page62() {
    return <PlayerInfoLayout
        previous={getGameRoute(PAGES.p61)}
        next={getGameRoute(PAGES.p63)}
        player={PLAYER.CHILD}
    />
}
