import CompareAnswersLayout from "../../components/compare-answers-layout";
import { MODULE_2_QUESTIONS } from "../../modules/main/hooks/use-final-score";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page30() {
    return <CompareAnswersLayout
        previous={getGameRoute(PAGES.p29)}
        next={getGameRoute(PAGES.p31)}
        questions={MODULE_2_QUESTIONS}
    />
}
