import styled from "styled-components";
import ImageLayout2 from "../../components/image-layout-2";
import { getGameRoute, PAGES } from "../../routes/routes";
import imgSrc from './male.png';

export default function Page44Male() {
    return <ImageLayout2
        imgSrc={imgSrc}
        imgAlt={"Rozmowa na zoomie w której biorą udział Kazik, jego tata i pedagog szkolny. Pedagog informuje, że chciałby dowiedzieć się jak mogą, jako szkoła, pomóc Kazikowi w podjęciu decyzji.  "}
        previous={getGameRoute(PAGES.p43)}
        next={getGameRoute(PAGES.p45)}
    />
}
const Container = styled.div`
img{
    width:1407px;
    height: 883px;
}


`
