import PlayerInfoLayout from "../../components/player-info-layout";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page33() {
    return <PlayerInfoLayout
        previous={getGameRoute(PAGES.p32)}
        next={getGameRoute(PAGES.p34)}
        player={PLAYER.CHILD}
    />
}
