import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page28Female() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p27)}
        next={getGameRoute(PAGES.p29)}
        player={PLAYER.PARENT}
    >
        <SingleAnswerQuestion
            question={"Jak myślisz, dlaczego uczennica nie chce pojechać na zieloną szkołę?"}
            questionId={"m-2-5"}
            answers={[
                "Bo ten czas chce przeznaczyć na naukę, przygotowanie do konkursów kuratoryjnych.",
                "Bo ma problemy z relacjami z rówieśnikami.",
                "Bo się boi, nigdy wcześniej nigdzie nie wyjechała bez rodziców.",
                "Bo woli w tym czasie samemu organizować sobie czas (granie na komputerze/zajęcia sportowe/ spotkania ze znajomymi).",
                "Rodzice uważają, że to strata czasu i pieniędzy.",
            ]} />
    </SingleAnswerLayout>
}
