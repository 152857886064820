import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page14Female() {
    return <PageLayout
        previous={getGameRoute(PAGES.p13)}
        next={getGameRoute(PAGES.p15)}
        nextLabel="Kolejna część"
        decors={DECOR_CONFIG.OPEN_QUESTIONS}
    >
        <p>Jakie emocje, związane z końcem szkoły podstawowej, Ci towarzyszą? </p>
        <p>Czy są one podobne do emocji bohaterów? </p>
        <p>Czy widzisz podobieństwa między swoimi doświadczeniami a tymi opisanymi w historii Krysi i jej taty?</p>
    </PageLayout>
}
