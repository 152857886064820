import styled from "styled-components";
import useFocusOnMount from "../../utils/use-focus-on-mount";
import ColumnLayout from "../column-layout";
import NiceButton from "../nice-button";

type Props = {
    imgSrc: string,
    imgAlt: string,
    previous: string,
    previousLabel?: string,
    next: string,
    nextLabel?: string,
    description?: string
}

const Container = styled.main`
    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: auto;
        max-width: 100%;
        display: inline-block;
        max-height: 90vh;

        &:focus {
            outline: none;
        }
    }

    .description {
        width: 100%;
        background: ${(props) => props.theme.boxBackground};
        margin-bottom: 1em;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export default function ImageLayout2({ imgSrc, imgAlt, previous, previousLabel, next, nextLabel, description }: Props) {

    const target = useFocusOnMount();

    return <ColumnLayout
        left={<NiceButton to={previous} label={previousLabel || 'Wróć'} />}
        right={<NiceButton to={next} label={nextLabel || 'Dalej'} />}
    >
        <Container>
            {
                description && <div className="description">
                    <p>
                        {description}
                    </p>
                </div>
            }
            <div className="img-container">
                <img ref={target} src={imgSrc} alt={imgAlt} />
            </div>
        </Container>
    </ColumnLayout>
}
