import styled from "styled-components";

import Box from "../../components/box";
import ColumnLayout from "../../components/column-layout";
import NiceButton from "../../components/nice-button";
import { getGameRoute, PAGES } from "../../routes/routes";
import Page73Widget from "./widget";

const Container = styled.main`
    display: flex;
    max-width: 1550px;

    .left-nav {
        flex: 0 0 15%;
    }

    .right-nav {
        flex: 0 0 15%;
        align-self: flex-end;

        display: flex;
        justify-content: flex-end;
    }

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
`;

const items: string[] = [
    'Przygotuję się na jutrzejszy sprawdzian z angielskiego.',
    'Podbiję legitymację (pojutrze wycieczka).',
    'Wybiorę szkoły, do których chcę kandydować (są jeszcze trzy miesiące).',
    'Będę uprawiała więcej sportu - zadbam o siebie.',
    'Wyrzucę śmieci.',
    'Oddam książki do biblioteki.',
    'Obejrzę serial.',
    'Posiedzę na insta albo fb.'
]

export default function Page73Female() {
    return <ColumnLayout
        left={<NiceButton to={getGameRoute(PAGES.p72)} label={'Wróć'} />}
        right={<NiceButton to={getGameRoute(PAGES.p74)} label={'Dalej'} />}
    >
        <Container>
            <Box>
                <p>Dopasujcie poniższe zdania do odpowiednich, Waszym zdaniem, miejsc w tabeli.</p>
                <Page73Widget items={items} />
            </Box>
        </Container>
    </ColumnLayout>
}
