import { useMemo } from "react"
import { CHARACTER_GENDER, MAIN_MODULE_ACTION, useMainModuleDispatch } from "../../modules/main"
import { PAGES } from "../routes"

import Page5 from "../../game-pages/p05"
import Page6Male from "../../game-pages/p06/male"
import Page7Male from "../../game-pages/p07/male"
import Page8Male from "../../game-pages/p08/male"
import Page9 from "../../game-pages/p09"
import Page10Male from "../../game-pages/p10/male"
import Page13 from "../../game-pages/p13"
import Page11Male from "../../game-pages/p11/male"
import Page12Male from "../../game-pages/p12/male"
import Page14Male from "../../game-pages/p14/male"
import Page15Male from "../../game-pages/p15/male"
import Page16 from "../../game-pages/p16"
import Page17Male from "../../game-pages/p17/male"
import Page18Male from "../../game-pages/p18/male"
import Page19Male from "../../game-pages/p19/male"
import Page20Male from "../../game-pages/p20/male"
import Page21Male from "../../game-pages/p21/male"
import Page22Male from "../../game-pages/p22/male"
import Page23 from "../../game-pages/p23"
import Page24Male from "../../game-pages/p24/male"
import Page25Male from "../../game-pages/p25/male"
import Page26Male from "../../game-pages/p26/male"
import Page27Male from "../../game-pages/p27/male"
import Page29Male from "../../game-pages/p29/male"
import Page28Male from "../../game-pages/p28/male"
import Page30 from "../../game-pages/p30"
import Page31 from "../../game-pages/p31"
import Page33 from "../../game-pages/p33"
import Page34Male from "../../game-pages/p34/male"
import Page35Male from "../../game-pages/p35/male"
import Page36Male from "../../game-pages/p36/male"
import Page37 from "../../game-pages/p37"
import Page40Male from "../../game-pages/p40/male"
import Page38Male from "../../game-pages/p38/male"
import Page39Male from "../../game-pages/p39/male"
import Page41 from "../../game-pages/p41"
import Page42 from "../../game-pages/p42"
import Page44Male from "../../game-pages/p44/male"
import Page45 from "../../game-pages/p45"
import Page46 from "../../game-pages/p46"
import Page47 from "../../game-pages/p47"
import Page48 from "../../game-pages/p48"
import Page49 from "../../game-pages/p49"
import Page51 from "../../game-pages/p51"
import Page50Male from "../../game-pages/p50/male"
import Page52 from "../../game-pages/p52"
import Page53 from "../../game-pages/p53"
import Page54 from "../../game-pages/p54"
import Page55 from "../../game-pages/p55"
import Page56 from "../../game-pages/p56"
import Page57Male from "../../game-pages/p57/male"
import Page58 from "../../game-pages/p58"
import Page59 from "../../game-pages/p59"
import Page60 from "../../game-pages/p60"
import Page61 from "../../game-pages/p61"
import Page62 from "../../game-pages/p62"
import Page63 from "../../game-pages/p63"
import Page64 from "../../game-pages/p64"
import Page65 from "../../game-pages/p65"
import Page66 from "../../game-pages/p66"
import Page67 from "../../game-pages/p67"
import Page68 from "../../game-pages/p68"
import Page69 from "../../game-pages/p69"
import Page70Male from "../../game-pages/p70/male"
import Page71 from "../../game-pages/p71"
import Page74 from "../../game-pages/p74"
import Page75 from "../../game-pages/p75"
import Page76Male from "../../game-pages/p76/male"
import Page77 from "../../game-pages/p77"
import Page78Male from "../../game-pages/p78/male"
import Page79 from "../../game-pages/p79"
import Page80Male from "../../game-pages/p80/male"
import Page81Male from "../../game-pages/p81/male"
import Page82Male from "../../game-pages/p82/male"
import Page83 from "../../game-pages/p83"
import Page84Male from "../../game-pages/p84/male"
import Page85 from "../../game-pages/p85/index"
import Page86 from "../../game-pages/p86"
import Page87Male from "../../game-pages/p87/male"
import Page88 from "../../game-pages/p88"
import Page89 from "../../game-pages/p89"
import Page90 from "../../game-pages/p90"
import Page91Male from "../../game-pages/p91/male"
import Page92Male from "../../game-pages/p92/male"
import Page93 from "../../game-pages/p93"
import Page94Male from "../../game-pages/p94/male"
import Page95 from "../../game-pages/p95"
import Page6Female from "../../game-pages/p06/female"
import Page7Female from "../../game-pages/p07/female"
import Page8Female from "../../game-pages/p08/female"
import Page10Female from "../../game-pages/p10/female"
import Page11Female from "../../game-pages/p11/female"
import Page12Female from "../../game-pages/p12/female"
import Page14Female from "../../game-pages/p14/female"
import Page15Female from "../../game-pages/p15/female"
import Page17Female from "../../game-pages/p17/female"
import Page18Female from "../../game-pages/p18/female"
import Page19Female from "../../game-pages/p19/female"
import Page20Female from "../../game-pages/p20/female"
import Page21Female from "../../game-pages/p21/female"
import Page22Female from "../../game-pages/p22/female"
import Page24Female from "../../game-pages/p24/female"
import Page25Female from "../../game-pages/p25/female"
import Page26Female from "../../game-pages/p26/female"
import Page27Female from "../../game-pages/p27/female"
import Page28Female from "../../game-pages/p28/female"
import Page29Female from "../../game-pages/p29/female"
import Page34Female from "../../game-pages/p34/female"
import Page35Female from "../../game-pages/p35/female"
import Page36Female from "../../game-pages/p36/female"
import Page38Female from "../../game-pages/p38/female"
import Page39Female from "../../game-pages/p39/female"
import Page40Female from "../../game-pages/p40/female"
import Page44Female from "../../game-pages/p44/female"
import Page50Female from "../../game-pages/p50/female"
import Page57Female from "../../game-pages/p57/female"
import Page70Female from "../../game-pages/p70/female"
import Page76Female from "../../game-pages/p76/female"
import Page78Female from "../../game-pages/p78/female"
import Page80Female from "../../game-pages/p80/female"
import Page81Female from "../../game-pages/p81/female"
import Page82Female from "../../game-pages/p82/female"
import Page84Female from "../../game-pages/p84/female"
import Page87Female from "../../game-pages/p87/female"
import Page91Female from "../../game-pages/p91/female"
import Page92Female from "../../game-pages/p92/female"
import Page94Female from "../../game-pages/p94/female"
import Page32Female from "../../game-pages/p32/female"
import Page32Male from "../../game-pages/p32/male"
import Page72Male from "../../game-pages/p72/male"
import Page72Female from "../../game-pages/p72/female"
import Page73Male from "../../game-pages/p73/male"
import Page73Female from "../../game-pages/p73/female"
import ScrollToMe from "@mborecki/react-scroll-to-me"
import Page4aFemale from "../../game-pages/p04a/female"
import Page4aMale from "../../game-pages/p04a/male"
import Page4bFemale from "../../game-pages/p04b/female"
import Page4bMale from "../../game-pages/p04b/male"

type Props = {
    id: string,
    character: CHARACTER_GENDER
}

type PageInfo = {
    id: string,
    male: React.FC,
    female: React.FC,
}

const Index: PageInfo[] = [
    { id: PAGES.p4a, male: Page4aMale, female: Page4aFemale },
    { id: PAGES.p4b, male: Page4bMale, female: Page4bFemale },
    { id: PAGES.p5, male: Page5, female: Page5 },
    { id: PAGES.p6, male: Page6Male, female: Page6Female },
    { id: PAGES.p7, male: Page7Male, female: Page7Female },
    { id: PAGES.p8, male: Page8Male, female: Page8Female },
    { id: PAGES.p9, male: Page9, female: Page9 },
    { id: PAGES.p10, male: Page10Male, female: Page10Female },
    { id: PAGES.p11, male: Page11Male, female: Page11Female },
    { id: PAGES.p12, male: Page12Male, female: Page12Female },
    { id: PAGES.p13, male: Page13, female: Page13 },
    { id: PAGES.p14, male: Page14Male, female: Page14Female },
    { id: PAGES.p15, male: Page15Male, female: Page15Female },
    { id: PAGES.p16, male: Page16, female: Page16 },
    { id: PAGES.p17, male: Page17Male, female: Page17Female },
    { id: PAGES.p18, male: Page18Male, female: Page18Female },
    { id: PAGES.p19, male: Page19Male, female: Page19Female },
    { id: PAGES.p20, male: Page20Male, female: Page20Female },
    { id: PAGES.p21, male: Page21Male, female: Page21Female },
    { id: PAGES.p22, male: Page22Male, female: Page22Female },
    { id: PAGES.p23, male: Page23, female: Page23 },
    { id: PAGES.p24, male: Page24Male, female: Page24Female },
    { id: PAGES.p25, male: Page25Male, female: Page25Female },
    { id: PAGES.p26, male: Page26Male, female: Page26Female },
    { id: PAGES.p27, male: Page27Male, female: Page27Female },
    { id: PAGES.p28, male: Page28Male, female: Page28Female },
    { id: PAGES.p29, male: Page29Male, female: Page29Female },
    { id: PAGES.p30, male: Page30, female: Page30 },
    { id: PAGES.p31, male: Page31, female: Page31 },
    { id: PAGES.p32, male: Page32Male, female: Page32Female },
    { id: PAGES.p33, male: Page33, female: Page33 },
    { id: PAGES.p34, male: Page34Male, female: Page34Female },
    { id: PAGES.p35, male: Page35Male, female: Page35Female },
    { id: PAGES.p36, male: Page36Male, female: Page36Female },
    { id: PAGES.p37, male: Page37, female: Page37 },
    { id: PAGES.p38, male: Page38Male, female: Page38Female },
    { id: PAGES.p39, male: Page39Male, female: Page39Female },
    { id: PAGES.p40, male: Page40Male, female: Page40Female },
    { id: PAGES.p41, male: Page41, female: Page41 },
    { id: PAGES.p42, male: Page42, female: Page42 },
    { id: PAGES.p43, male: Page44Male, female: Page44Female },
    { id: PAGES.p44, male: Page44Male, female: Page44Female },
    { id: PAGES.p45, male: Page45, female: Page45 },
    { id: PAGES.p46, male: Page46, female: Page46 },
    { id: PAGES.p47, male: Page47, female: Page47 },
    { id: PAGES.p48, male: Page48, female: Page48 },
    { id: PAGES.p49, male: Page49, female: Page49 },
    { id: PAGES.p50, male: Page50Male, female: Page50Female },
    { id: PAGES.p51, male: Page51, female: Page51 },
    { id: PAGES.p52, male: Page52, female: Page52 },
    { id: PAGES.p53, male: Page53, female: Page53 },
    { id: PAGES.p54, male: Page54, female: Page54 },
    { id: PAGES.p55, male: Page55, female: Page55 },
    { id: PAGES.p56, male: Page56, female: Page56 },
    { id: PAGES.p57, male: Page57Male, female: Page57Female },
    { id: PAGES.p58, male: Page58, female: Page58 },
    { id: PAGES.p59, male: Page59, female: Page59 },
    { id: PAGES.p60, male: Page60, female: Page60 },
    { id: PAGES.p61, male: Page61, female: Page61 },
    { id: PAGES.p62, male: Page62, female: Page62 },
    { id: PAGES.p63, male: Page63, female: Page63 },
    { id: PAGES.p64, male: Page64, female: Page64 },
    { id: PAGES.p65, male: Page65, female: Page65 },
    { id: PAGES.p66, male: Page66, female: Page66 },
    { id: PAGES.p67, male: Page67, female: Page67 },
    { id: PAGES.p68, male: Page68, female: Page68 },
    { id: PAGES.p69, male: Page69, female: Page69 },
    { id: PAGES.p70, male: Page70Male, female: Page70Female },
    { id: PAGES.p71, male: Page71, female: Page71 },
    { id: PAGES.p72, male: Page72Male, female: Page72Female },
    { id: PAGES.p73, male: Page73Male, female: Page73Female },
    { id: PAGES.p74, male: Page74, female: Page74 },
    { id: PAGES.p75, male: Page75, female: Page75 },
    { id: PAGES.p76, male: Page76Male, female: Page76Female },
    { id: PAGES.p77, male: Page77, female: Page77 },
    { id: PAGES.p78, male: Page78Male, female: Page78Female },
    { id: PAGES.p79, male: Page79, female: Page79 },
    { id: PAGES.p80, male: Page80Male, female: Page80Female },
    { id: PAGES.p81, male: Page81Male, female: Page81Female },
    { id: PAGES.p82, male: Page82Male, female: Page82Female },
    { id: PAGES.p83, male: Page83, female: Page83 },
    { id: PAGES.p84, male: Page84Male, female: Page84Female },
    { id: PAGES.p85, male: Page85, female: Page85 },
    { id: PAGES.p86, male: Page86, female: Page86 },
    { id: PAGES.p87, male: Page87Male, female: Page87Female },
    { id: PAGES.p88, male: Page88, female: Page88 },
    { id: PAGES.p89, male: Page89, female: Page89 },
    { id: PAGES.p90, male: Page90, female: Page90 },
    { id: PAGES.p91, male: Page91Male, female: Page91Female },
    { id: PAGES.p92, male: Page92Male, female: Page92Female },
    { id: PAGES.p93, male: Page93, female: Page93 },
    { id: PAGES.p94, male: Page94Male, female: Page94Female },
    { id: PAGES.p95, male: Page95, female: Page95 },
]

export default function GamePage({ id, character }: Props) {
    const dispatch = useMainModuleDispatch();
    const Page = useMemo(() => {
        const infoIndex = Index.findIndex(p => p.id === id);
        const info = Index[infoIndex];

        dispatch({
            type: MAIN_MODULE_ACTION.SET_GAME_PROGRESS,
            payload: {
                progress: Math.min(100, infoIndex / (Index.length - 2))
            }
        })

        if (info) {
            if (character === CHARACTER_GENDER.MALE) {
                return info.male;
            }

            if (character === CHARACTER_GENDER.FEMALE) {
                return info.female;
            }
        }

        return () => <p>Nieznana strona!</p>
    }, [id, character, dispatch])

    return <>
        <ScrollToMe trigger={id} behavior="smooth" />
        <Page />
    </>
}
