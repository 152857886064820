import MultipleAnswerQuestionLayout from "../../components/multiple-answer-layout";
import MultipleAnswerQuestion from "../../components/multiple-answer-layout/multiple-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page78Female() {
    return <MultipleAnswerQuestionLayout
        previous={getGameRoute(PAGES.p77)}
        next={getGameRoute(PAGES.p79)}
        player={PLAYER.CHILD}
    >
        <MultipleAnswerQuestion
            question={"Z jakiego powodu, Twoim zdaniem, Krysi nie poszedł próbny egzamin? (wskaż 3 najbardziej prawdopodobne Twoim zdaniem przyczyny)"}
            questionId={"m-7-1"}
            force={3}
            answers={[
                "Była niewyspana.",
                "Zjadł ją stres.",
                "Nie mogła się skoncentrować.",
                "Nie przygotowała się, nie powtórzyła materiału.",
                "Miała pecha - same zadania akurat z tego z czego nie jest najlepsza.",
                "To było dopiero pierwsze podejście.",
            ]}
        />

    </MultipleAnswerQuestionLayout>
}
