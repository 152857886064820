import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import ModulesProviders from './modules/providers';
import { QueryClient, QueryClientProvider } from 'react-query';

import './style.scss';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/array/fill';
import 'core-js/features/array/is-array';
import 'core-js/features/object/values'
import 'core-js/features/number/is-nan';
import 'core-js/features/string/starts-with';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from './styles/theme';

const queryClient = new QueryClient()


function AppWithCallbackAfterRender() {
    return <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ModulesProviders>
                <ThemeProvider theme={defaultTheme}>
                    <App />
                </ThemeProvider>
            </ModulesProviders>

        </QueryClientProvider>
    </React.StrictMode >
}

const container = document.getElementById('root') || document.body;
const root = ReactDOM.createRoot(container);
root.render(<AppWithCallbackAfterRender />);
