import CompareAnswersLayout from "../../components/compare-answers-layout";
import { MODULE_3_QUESTIONS } from "../../modules/main/hooks/use-final-score";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page41() {
    return <CompareAnswersLayout
        previous={getGameRoute(PAGES.p40)}
        next={getGameRoute(PAGES.p42)}
        questions={MODULE_3_QUESTIONS}
    />
}
