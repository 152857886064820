import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import Page92Widget2 from "./widget-2";

export default function Page92Female() {
    return <PageLayout previous={getGameRoute(PAGES.p91)} next={getGameRoute(PAGES.p93)}>
        <p>Dopasujcie wspólnie interpretacje do przykładowych reakcji rodziców.</p>
        <Page92Widget2
            relations ={[
                "Super, udało Ci się!",
                "Brawo, wspaniale, gratulujemy.",
                "Oooooo, jesteśmy z Ciebie dumni, należy ci się nagroda.",
                "Widzzimy, że Twoje wysiłki, praca przyniosły efekt. Jesteśmy dumni z Twojej wytrwałości, przygotowań, pracy. Gratulujemy.",
                "No to poleciałaś. Spoko, nie martw, jakoś to będzie.",
                "A nie mówiliśmy, że jak nie będziesz pracowała, to i wyników nie będzie.",
                "Co teraz będzie? No to możemy zapomnieć o tej szkole, o której tak marzyłaś. Musimy teraz szukać innej.",
                "I jak Ci jest z tymi wynikami? Chcesz pogadać o tym? Jesteśmy gotowi. Jak Ty będziesz gotowa, to przyjdź. Bardzo chcielibyśmy Cię wysłuchać."
            ]}
            interpretations={[
                "To nie Twoja zasługa, tylko miałaś szczęście.",
                "Wiem, że mogę na nich liczyć, nawet teraz. Czuję, że komuś muszę powiedzieć jak mi teraz jest źle. Dobrze, że chcą mnie wysłuchać.",
                "Jak zwykle nawaliłam, rodzice wiedzieli, że tak będzie i nawet nie są zaskoczeni.",
                "Fajnie, cieszę się.",
                "Widzicie moją pracę, potraficie wskazać dokładnie za co jesteście dumni. Czuję, że to jest prawdziwe.",
                "A czy gdybym miała inny wyniki, bylibyście ze mnie dumni, dostałabym nagrodę?",
                "Wszystkich zawiodłam i jeszcze zrobiłam problemy, teraz nie dość, że mają dużo swoich spraw, to jeszcze będą martwić się moją szkołą. Przegryw ze mnie.",
                "No i wyszło na ich, miał rację. Jestem do niczego, zawiodłam ich."
            ]}
        />
        <div style={{height: '10em'}}></div>
    </PageLayout>

}
