import { useMemo } from "react";
import PageLayout from "../../components/page-layout";
import useFinalScore from "../../modules/main/hooks/use-final-score";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page94Male() {

    const score = useFinalScore();

    const endingId = useMemo(() => {
        if (score >= 75) {
            return 0;
        }

        if (score >= 30) {
            return 1;
        }

        return 2;
    }, [score]);

    return <PageLayout
        previous={getGameRoute(PAGES.p93)}
        next={getGameRoute(PAGES.p95)}
        decors={DECOR_CONFIG.STORY_4_MALE}
    >
        {
            (endingId === 0) && <div>
                <p className='talk-phase'> Stresujesz się? - pyta Kazika Adam</p>
                <p className='talk-phase'> Nie. No może. Dobra, fest - odpowiada wyraźnie zdenerwowany Kazik.</p>
                <p className='talk-phase'> A czym najbardziej? - dopytuje Adam. Może rozmową uda się trochę uspokoić nerwy.</p>
                <p className='talk-phase'> Nowe miejsce, nowi ludzie, no… wszystko - odpowiada Kazik drżącym głosem.</p>
                <p><i>Faktycznie,</i> myśli Adam. Właśnie podjeżdżają do nowej szkoły Kazika. To pierwszy dzień, początek rozdziału. Sam jest lekko zestresowany i wzruszony.</p>
                <p className='talk-phase'> Ty wiesz, ja chyba też się stresuję jak Ci pójdzie - przyznaje Adam.</p>
                <p className='talk-phase'> Serio? - pyta Kazik. W sumie rozumie swojego tatę.</p>
                <p className='talk-phase'> Ale chyba tutaj już Cię wysadzę. To trochę przypał do takiej szkoły z ojcem jechać… - Adam pamięta swój pierwszy dzień w nowej szkole i wstyd dzieciaków, które odprowadzali rodzice. W końcu jego syn jest już prawie dorosły, prawda?</p>
                <p className='talk-phase'> Hym no niby fakt. Tylko wiesz co…? - zawiesza głos Kazik.</p>
                <p className='talk-phase'> No co? - dopytuje Adam, ciekawy co syn ma do powiedzenia.</p>
                <p className='talk-phase'> Z takim ojcem to żaden przypał. Kocham Cię tato. Dzięki za wszystko - rzuca Kazik, wysiadając z samochodu.</p>
                <p>Zamykane głośno drzwi kończą rozmowę. Adam szybko ociera pojedynczą łzę wzruszenia. <i>No to lecimy do roboty,</i> odpala silnik i odjeżdża machając Kazikowi znikającemu za rogiem budynku.</p>
                
                <p><i><h3>Gratulacje! Osiągnęliście najwyższy poziom zgodności.</h3></i></p>
                <p><i>Tym samym odkryliście jedno z trzech zakończeń gry. Wynik gry zależy od tego w jakim stopniu odpowiedzieliście tak samo na pytania związane z historią Kazika i Adama.</i></p>
            </div>
        }
        {
            (endingId === 1) && <div>
                <p className='talk-phase'> Masz wszystko co trzeba? Dokumenty, książki, zeszyt? -  dopytuje zdenerwowany Adam.</p>
                <p className='talk-phase'> Po co pytasz, to pierwszy dzień. Co ty w szkole nie byłeś? - odpowiada Kazik, nie wiedząc czy bardziej denerwuje się pierwszym dniem czy pytaniami taty.</p>
                <p className='talk-phase'> Wiesz kiedy to było? -  próbuje żartować Adam, choć sam trochę się martwi jak pójdzie Kazikowi w szkole. Zwłaszcza, że nie była to ta wymarzona a jego trzeciego wyboru. <i>O czym by tu pogadać?</i> zastanawia się..</p>
                <p className='talk-phase'> Może po pracy pójdziemy do kina? Poświętowalibyśmy razem? - proponuje Adam.</p>
                <p className='talk-phase'> Tato, ja już mam plany. Dzięki - odpowiada Kazik, zakładając buty.</p>
                <p className='talk-phase'> Rozumiem. Wiesz, jeżeli będziesz potrzebować pogadać, jestem - kontynuuje Adam, usiłując przypomnieć sobie emocje, jakie towarzyszyły mu w pierwszym dniu nowej szkoły.</p>
                <p className='talk-phase'> Nie ma o czym. Chyba… - zawiesza głos Kazik.</p>
                <p>Adam patrzy na Kazika czekając aż ten dokończy zdanie.</p>
                <p className='talk-phase'> Dobra, wrócę to wieczorem pogadamy. Dzięki tato!</p>
                <p>Kazik złapał klucze i wyszedł.</p>
                <p>Adam macha przez okno Kazikowi wsiadającemu na rower. <i>Trzymam kciuki synku</i>- mówi w myślach.</p>

                <p><i><h3>Jest nieźle! Osiągnęliście średni poziom zgodności.</h3></i></p>
                <p><i>Tym samym odkryliście jedno z trzech zakończeń gry. Pomyślcie nad swoimi odpowiedziami, nad tym czego się o sobie dowiedzieliście. Rodzicu, zajrzyj do Bazy Wiedzy, zobacz jak możesz inaczej komunikować się z nastolatkiem. Za jakiś czas zagrajcie ponownie i poznajcie inne możliwe zakończenie.</i></p>
            </div>
        }
        {
            (endingId === 2) && <div>
                <p className='talk-phase'> Wstawaj Kazik, bo się spóźnisz! Trzeci raz nie będę prosić - Adam zaniepokojony spogląda na zegarek. <i>No spóźni się…</i></p>
                <p>Kazik wychodzi z pokoju i patrzy nieprzytomnie na tatę.</p>
                <p className='talk-phase'> Coś się stało? - pyta Kazik, ziewając.</p>
                <p className='talk-phase'> Za godzinę masz rozpoczęcie roku szkolnego! Co Ty robisz?! -  woła zdenerwowany Adam, patrząc na syna, który wciąż ma na sobie piżamę.</p>
                <p className='talk-phase'> Ale jak to… - pyta Kazik, drapiąc się po głowie.</p>
                <p className='talk-phase'> No co Ty synek, pakuj się i zasuwaj. Pojedziemy autem, będzie szybciej - mówi Adam, robiąc synowi kanapki. <i>Trudno, będzie musiał zjeść w aucie,</i> myśli.</p>
                <p className='talk-phase'> Po pierwsze – nie jestem dzieckiem, żebyś mnie woził… - zaczyna wyliczać Kazik wyraźnie zirytowany.</p>
                <p className='talk-phase'> Pomyśl o tym jak o taksówce. Chcę ci pomóc - odpowiada Adam spiętym głosem. <i>O co chodzi? Czemu Kazik jest taki nieprzyjemny?</i> zastanawia się.</p>
                <p className='talk-phase'> Po drugie – mam budzik, wiem kiedy wstawać. Nie potrzebuję niani - kontynuuje Kazik.</p>
                <p className='talk-phase'> No chyba jednak potrzebujesz… Zostało Ci 55 minut! Pierwsze wrażenie jest ważne - twierdzi lekko już panikujący Adam, szukając kluczyków do auta.</p>
                <p className='talk-phase'> Ostatnie też. Rozpoczęcie roku jest jutro. Zanim zaczniesz mi cisnąć sam się ogarnij - warczy Kazik, odwracając się na pięcie.</p>
                <p>Adam nie wierzy. Sprawdza i faktycznie, rozpoczęcie roku jest jutro. Jak to się stało?</p>
                <p className='talk-phase'> Ej synek, przepraszam… - słabym głosem mówi Adam. Ale Kazik znika w swoim pokoju. <i>Muszę z nim pogadać, ale może później…</i> myśli zasmucony Adam i rusza.</p>

                <p><i><h3>Jest o czym rozmawiać. Osiągnęliście podstawowy poziom zgodności.</h3></i></p>
                <p><i>Tym samym odkryliście jedno z trzech zakończeń gry. Pomyślcie nad swoimi odpowiedziami, nad tym czego się o sobie dowiedzieliście. Rodzicu, zajrzyj do Bazy Wiedzy, dowiedz się więcej o rozwoju młodego człowieka i sposobach komunikowania się z nim. Za jakiś czas zagrajcie ponownie i poznajcie inne możliwe zakończenie.</i></p>
            </div>
        }
    </PageLayout>
}
