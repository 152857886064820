import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";


export default function Page75() {
    return <PageLayout previous={getGameRoute(PAGES.p74)} next={getGameRoute(PAGES.p76)} nextLabel="Kolejna część"
    decors={DECOR_CONFIG.OPEN_QUESTIONS}>
        <p>Rodzicu, a Ty jak planujesz swoją pracę?</p>
        <h3>
            Wyzwanie:
        </h3>
        <p>
        Wypróbujcie najprostszą metodę do planowania pracy, czyli checklistę. Przez tydzień zapisujcie co macie do zrobienia na następny dzień. Wykonane zadania dumnie skreślajcie:) Możecie skorzystać z karteczek samoprzylepnych. Jedno zadanie zapisujcie na jednej karteczce. Przyczepcie je w widocznym miejscu (np. na lodówce). Na koniec dnia wspólnie odczepcie te, które zostały wykonane.
        </p>
    </PageLayout>
}
