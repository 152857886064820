import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page40Female() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p39)}
        next={getGameRoute(PAGES.p41)}
        player={PLAYER.PARENT}>
        <SingleAnswerQuestion
            question={"W jaki sposób tata Krysi może jej pomóc?"}
            questionId={'m-3-3'}
            answers={[
                "Obiecać super nagrodę za poprawienie wyników w nauce.",
                "Zakazać korzystania z komputera i smartfona, żeby zmusić Krysię do koncentracji na książkach i pracach domowych. ",
                "Zagrozić, że jeżeli nie poprawi ocen, to nie wyjedzie na obóz z koleżankami.",
                "Załatwić korepetycje.",
                "Zacząć wspólnie uczyć się z Krysią, poświęcić jej czas na wspólną naukę. ",
                "Pozwolić Krysi samej decydować o sobie rozmawiając o konsekwencjach jej działań.",
                "Zostawić Krysię w spokoju."
            ]}
        />
    </SingleAnswerLayout>
}
