import { useReducer, useCallback } from "react";
import styled from "styled-components";
import DropZone from "../../components/drag-drop-template/drop-zone";
import PickUp from "../../components/drag-drop-template/pick-up";

//@ts-ignore
import { Preview } from 'react-dnd-preview'

const Container = styled.div`
    .slots {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .slot {
    }

    .drop-zone {
        border: 2px solid black;
        padding: .5em;
        h4 {
            margin: 0;
            padding: 0 .5em;
            text-align: center;
        }
        min-height: 4em;

        &.is-over {
            border-color: #FCD779;
            box-shadow: inset 0 0 3px 3px #FCD779;
        }
    }
`;

type Item = {
    id: string,
    text: string
}

type Slot = {
    id: string,
    title: string,
    itemIds: string[]
}

type GameState = {
    items: Item[],
    unselected: string[],
    slots: Slot[]
}

const slots: Slot[] = [
    { id: 's1', title: 'Ważne i pilne', itemIds: [] },
    { id: 's2', title: 'Ważne i niepilne', itemIds: [] },
    { id: 's3', title: 'Nieważne i pilne', itemIds: [] },
    { id: 's4', title: 'Nieważne i niepilne', itemIds: [] }
]

export default function Page73Widget({ items }: { items: string[] }) {

    const [state, moveItem] = useReducer((state: GameState, action: { itemId: string, slotId: string }) => {
        return {
            ...state,
            unselected: state.unselected.filter(i => i !== action.itemId),
            slots: state.slots.map(slot => {
                if (slot.id === action.slotId) {
                    slot.itemIds = Array.from(new Set([...slot.itemIds, action.itemId]));
                } else {
                    slot.itemIds = slot.itemIds.filter(i => i !== action.itemId);
                }

                return slot
            })
        }
    }, {
        items: items.map((item, index) => {
            return {
                id: `i${index}`,
                text: item
            }
        }),
        unselected: items.map((item, index) => {
            return `i${index}`;
        }),
        slots
    });

    const onDrop = useCallback((pickupId: string, dropId: string) => {
        moveItem({
            itemId: pickupId,
            slotId: dropId
        });
    }, [moveItem])
    return <Container>
        <div className="slots">
            {
                state.slots.map(slot => {
                    return <DropZone id={slot.id} targetType="item" onDrop={onDrop} key={slot.id}>
                        <h4>{slot.title}</h4>
                        {
                            slot.itemIds.map(itemId => {
                                const item = state.items.find(i => i.id === itemId);

                                if (item) {
                                    return <div className="pickup-container" key={itemId}>
                                        <div className="pickup-container-inner">
                                            <PickUp data={{ type: 'item', id: item.id, data: item }}>
                                                <GameItem item={item} />
                                            </PickUp>
                                        </div>
                                    </div>
                                }

                                return <></>
                            })
                        }
                    </DropZone>
                })
            }
        </div>
        <div className="unselected">
            {
                state.unselected.map(itemId => {
                    const item = state.items.find(i => i.id === itemId);

                    if (item) {
                        return <div className="pickup-container" key={itemId}>
                            <div className="pickup-container-inner">
                                <PickUp data={{ type: 'item', id: item.id, data: item }}>
                                    <GameItem item={item} />
                                </PickUp>
                            </div>
                        </div>
                    }

                    return <></>
                })
            }
        </div>
        <ItemPreview />
    </Container>
}

const GameItemContainer = styled.div`
    display: inline-block;
    padding: .5em;
    border: 1px solid black;
    margin: .5em 0;
    width: calc(100% - 1em);

    background: ${props => props.theme.accentColor};

    &:hover {
        background: #FCD779
    }

    .preview-container & {
        background: #FCD779
    }
`;


const GameItem: React.FC<{ item: Item }> = ({ item }) => {
    return <GameItemContainer className=".item">{item.text}</GameItemContainer>
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }} className='preview-container'>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}
