import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page48() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p47)}
        next={getGameRoute(PAGES.p49)}
        player={PLAYER.CHILD}>
        <SingleAnswerQuestion
            question={"Jak Twoim zdaniem należy motywować do nauki?"}
            questionId={"m-4-2"}
            answers={[
                'Hmm, motywacja jest ważna. Myślę, że jakaś obiecana nagroda by się sprawdziła.',
                'Lubię jak rodzice ze mną rozmawiają, chcą usłyszeć z czym mam trudności i pozwalają działać po swojemu.'
            ]}
        />
    </SingleAnswerLayout>
}
