import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page50Female() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p49)}
        next={getGameRoute(PAGES.p51)}
        player={PLAYER.CHILD}>
        <SingleAnswerQuestion
            question={"W jaki sposób rodzic informuje Cię, o tym jak Cię postrzega?"}
            questionId={"m-4-3"}
            answers={[
                'Uważa, że jestem już duża i trzeba mi mówić jak jest, bez owijania w bawełnę.',
                'Stara się doceniać. Jeśli coś robię niewłaściwie, to mi o tym mówi. Ale nie ocenia, tylko konkretnie mówi co jego zdaniem nie jest ok.',
                'Świetnie, że lubisz rozmawiać. To trudna sztuka, często wymaga cierpliwości i otwartości.'
            ]}
        />
    </SingleAnswerLayout>
}
