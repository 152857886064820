import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page91Male() {
    return <PageLayout
        previous={getGameRoute(PAGES.p90)}
        next={getGameRoute(PAGES.p92)}
        decors={DECOR_CONFIG.STORY_5_MALE}
    >
        <p>Kazik z drżeniem dłoni wpisuje login i hasło do systemu. Już zaraz dowie się czy jego praca przyniosła efekty. W salonie czekają rodzice - Adam i Ewa. Mijają kolejne minuty a Kazik nie wychodzi z pokoju.</p>
        <p className='talk-phase'> Myślisz, że coś się stało? Może zapukamy? - zastanawia się przejęta Ewa.</p>
        <p className='talk-phase'> Zostaw go w spokoju kochanie, to ważny moment - Adam stara się zachowywać pozory spokoju, żeby nie nakręcać żony.</p>
        <p className='talk-phase'> A co jak siedzi przy otwartym oknie i się przeziębi? - pyta Ewa, która ewidentnie szuka powodu, żeby pójść do pokoju syna.</p>
        <p className='talk-phase'> Jest lipiec a na zewnątrz 30 stopni… - wypowiedź Adama przerywa Ewa, zrywając się z fotela.</p>
        <p className='talk-phase'> Ruszył się! Słyszę szuranie krzesła!</p>
        <p className='talk-phase'> Jak ty to… - zaczyna Adam ale urywa, gdy zauważa otwierające się drzwi pokoju syna.</p>
        <p>Kazik wchodzi do salonu z miną pokerzysty.</p>
        <p className='talk-phase'> Mamo, Tato…</p>
    </PageLayout>
}
