import styled from "styled-components";

const StoryInfo = styled.section`
    font-style: italic;
    font-weight: bold;
    line-height: 1.5em;

    dl {
        display: flex;
        flex-wrap: wrap;
    }

    ul {
        width: 100%;

        li {
            margin: .5em 0 .5em 1em;
            list-style: none;
        }
    }

    dt {
        display: inline-block;
        padding-right: .5em;

        &.bigger {
            font-size: 1.17em;
        }
    }

    dd {
        clear: right;
        display: contents;

        &:after {
            content: '';
            width: 100%;
            height: 1em;
        }
    }
`

export default StoryInfo;
