import styled from "styled-components";
import Box from "../../components/box";
import PageFooter from "../../components/footer";
import NiceButton from "../../components/nice-button";
import { getHomeRoute } from "../routes";

const Container = styled.div`
min-height: 100vh;
width: 100%;

display: flex;
flex-direction: column;
align-items: stretch;

main {
    width: 95%;
    max-width: 1520px;
    margin: 0 auto;
    flex: 1 1 auto;
    position: relative;
    padding: 5vh 0;

    display: flex;
    align-items: center;

    .wrapper {
        display: grid;
        grid-template-columns: 15% 85%;
        gap: .5em
    }

    nav {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

footer {
    background: white;
    flex: 0 0 100px;
}

ul {
    margin: .7em .7em .7em 2em;
}

.challange {
    font-style: italic;
}
div>video{
    width: 100%;
    padding-top: 5%;
}
`;

export default function LetsTalkPage() {
    return <Container>
        <main>
            <div className="wrapper">

                <nav>
                    <NiceButton to={getHomeRoute()} label="Wróć" />
                </nav>
                <Box>
                <div><video src="/assets/o-innowacji.mp4" controls></video></div>
                <h2>O innowatorach</h2>

                <h3>Bakcyl i Laba</h3>
                <p>Naszym celem jest wspieranie nauczycieli, uczniów i rodziców w zdobywaniu kompetencji potrzebnych do skutecznego uczenia i uczenia się. Uważamy, że fundamentem edukacji powinien być szacunek i uznanie autentycznej podmiotowości młodych ludzi. Chcemy zarażać bakcylem wiedzy i umiejętności ale wiemy też, że równie ważne jest zrobienie sobie od czasu do czasu laby.</p>

                <h3>Niepubliczna Szkoła Podstawowa Edulab</h3>
                <p>
                    W naszej pracy bazujemy na relacji między nauczycielem i uczniem, jesteśmy zainteresowani tym, co dla naszych uczniów jest ważne i o czym chcą z nami rozmawiać. Nasi uczniowie nie tylko nabywają wiedzę i umiejętności z różnych przedmiotów, ale także uczą się postaw będących fundamentem dorosłego życia: odpowiedzialności osobistej, odwagi, współpracy i autentyczności.

                </p>

                </Box>
            </div>
        </main>
        <PageFooter />
    </Container>
}
