import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";

export default function Page82Female() {
    return <PageLayout
        previous={getGameRoute(PAGES.p81)}
        next={getGameRoute(PAGES.p83)}
        decors={DECOR_CONFIG.STORY_5_FEMALE}
    >
        <p>Auto weszło w zakręt i z piskiem opon mknie ulicą Szkolną. W środku nasi bohaterowie: Krysia i Adam i stosy notatek przewalające się po kabinie auta.</p>
        <p className='talk-phase'> Pamiętaj córciu, mamy jeszcze pół godziny a pierwszym królem Polski był…? - pyta Adam.</p>
        <p className='talk-phase'> To czemu tak pędzimy? Mieszko? A nie Chrobry, Chrobry tak tak. Możemy wolniej? - prosi zdenerwowana Krysia.</p>
        <p className='talk-phase'> Tak już zwalniam, może jeszcze staniemy na chwilę i meliskę wypijemy? Mama zrobiła - proponuje Adam, równie zestresowany co córka.</p>
        <p>Krysia przez szybę auta zauważa Tadka i Sandrę, którzy właśnie wysiedli z autobusu.</p>
        <p className='talk-phase'> O to może ja już z nimi pójdę - proponuje nieśmiało Krysia, która jak najszybciej chce mieć ten egzamin za sobą.</p>
        <p className='talk-phase'> Dobra ale pamiętaj…! - zaczyna Adam</p>
        <p className='talk-phase'> O czym? -  pyta lekko zirytowana Krysia.</p>
        <p className='talk-phase'> No pamiętaj…</p>
        <p>Co, Twoim zdaniem, Adam powinien powiedzieć Krysi przed egzaminem?</p>
    </PageLayout>
}

