import ImageLayout2 from "../../components/image-layout-2";
import { getGameRoute, PAGES } from "../../routes/routes";
import imgSrc from './img.png';

export default function Page32Female() {
    return <ImageLayout2
        imgSrc={imgSrc}
        imgAlt={"Rozmowa na czacie z Tadzikiem. Krysia napisała właśnie egzamin próbny i jest zdołowana. Tadzik próbuje pocieszyć koleżankę, ale nie potrafi się utożsamić z jej problemem. Nasza bohaterka zastanawia się co zrobić ale nie dochodzi do żadnej konkluzji."}
        previous={getGameRoute(PAGES.p31)}
        next={getGameRoute(PAGES.p33)}
        description={"Krysia po próbnych egzaminach pisze do Tadzika."}
    />
}
