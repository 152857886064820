import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page66() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p65)}
        next={getGameRoute(PAGES.p67)}
        player={PLAYER.PARENT}
    >
        <SingleAnswerQuestion
            question={"Jaki, Twoim zdaniem, profil szkoły jest aktualnie najlepszym wyborem?"}
            questionId={"m-5-1"}
            answers={[
                "Branżówka – daje możliwość szybkiego uzyskania konkretnego zawodu, mniej przedmiotów ogólnych, a więcej praktycznych, a maturę zawsze można zrobić później.",
                "Technikum – trwa dłużej niż liceum, ale daje uprawnienia do konkretnego zawodu, no i spokojnie można podejść do matury a potem może pójść na studia.",
                "Liceum – cztery lata nauki, a potem matura. Daje wiedzę ogólną, która pomoże w wyborze dalszej ścieżki kształcenia."
            ]} />
    </SingleAnswerLayout>
}
