import React, { useCallback, useMemo, useReducer } from "react";
import styled from "styled-components";
import { MAIN_MODULE_ACTION, PLAYER, useMainModuleDispatch } from "../../modules/main";
import Box from "../box";
import NiceButton from "../nice-button";
import { Card } from "./card";

//@ts-ignore
import { Preview } from 'react-dnd-preview'
import { useNavigate } from "react-router";
import ColumnLayout from "../column-layout";

type Props = {
    previous: string,
    previousLabel?: string,
    next: string,
    nextLabel?: string,
    questionId: string,
    player: PLAYER,
    instruction: string,
    items: string[]
}

const Container = styled.main`

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }

    .item {
        margin: .5em 0;
        display: grid;
        grid-template-columns: auto 10% 10%;
        gap: .5em;

        button {
            background: none;
            border: 1px solid black;
        }
    }
`;

type Item = {
    id: string,
    text: string
}

type GameState = {
    items: Item[]
}


export default function OrderQuestionLayout({ previous, previousLabel, next, nextLabel, items, questionId, instruction, player }: Props) {

    const initData: GameState = useMemo(() => {
        return {
            items: items.map((item, index) => {
                return {
                    id: `${index}`,
                    text: item
                }
            })
        }
    }, [items]);

    const [state, itemMove] = useReducer((state: GameState, { dragIndex, hoverIndex }: { dragIndex: number, hoverIndex: number }) => {
        const copyArr = [...state.items];
        copyArr.splice(hoverIndex, 0, copyArr.splice(dragIndex, 1)[0]);

        return {
            ...state,
            items: [...copyArr]
        }
    }, initData)

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        itemMove({ dragIndex, hoverIndex })
    }, []);

    const dispatch = useMainModuleDispatch();
    const navigate = useNavigate();
    const onNext = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        state.items.forEach((item, index) => {
            dispatch({
                type: MAIN_MODULE_ACTION.SAVE_ANSWER,
                payload: {
                    questionId: `${questionId}-${index}`,
                    answer: item.id,
                    player
                }
            });
        })
        navigate(next);
    }, [dispatch, state, navigate, next, player, questionId]);

    const moveUp = useCallback((index: number) => {
        if (index > 0) {
            moveCard(index, index - 1)
        }
        document.querySelectorAll('.item')[index-1]?.querySelectorAll('button')[0]?.focus();
    }, [moveCard]);

    const moveDown = useCallback((index: number) => {
        if (index < items.length) {
            moveCard(index, index + 1)
        }
        document.querySelectorAll('.item')[index+1]?.querySelectorAll('button')[1]?.focus();
    }, [moveCard, items]);

    return <ColumnLayout
        left={<NiceButton to={previous} label={previousLabel || 'Wróć'} />}
        right={<NiceButton to={next} label={nextLabel || 'Dalej'} onClick={onNext} />}
    >
        <Container>
            <Box>
                <p>{instruction}</p>
                <div aria-live='assertive'>
                    {
                        state.items.map((item, index) => {

                            if (item) {
                                return <div className={`item`} key={index}>
                                    <Card id={item.id} index={index} moveCard={moveCard} data={item} >
                                        <GameItem item={item} />
                                    </Card>
                                    <button onClick={() => moveUp(index)} aria-label={`Pozycja ${index+1}: ${item.text} Przenieś wyżej`}>
                                        <span className="material-icons">
                                            &#xe5d8;
                                        </span>
                                    </button>
                                    <button onClick={() => moveDown(index)} aria-label={`Pozycja ${index+1}: ${item.text} Przenieś niżej`}>
                                        <span className="material-icons">
                                            &#xe5db;
                                        </span>
                                    </button>
                                </div>
                            }

                            return <></>

                        })
                    }
                </div>
                <ItemPreview />
            </Box>
        </Container>
    </ColumnLayout>
}

const GameItemContainer = styled.div`
    display: inline-block;
    padding: .5em;
    border: 1px solid black;
    width: calc(100% - 1em);

    background: ${props => props.theme.accentColor};

    &:hover {
        background: #FCD779
    }



    .preview-container & {
        background: #FCD779
    }
`;

const GameItem: React.FC<{
    item: Item
}> = ({ item }) => {
    return <GameItemContainer className={`pick-up`}>
        {item.text}
    </GameItemContainer>
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }} className='preview-container'>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}
