import SingleAnswerLayout from "../../components/single-answer-layout";
import SingleAnswerQuestion from "../../components/single-answer-layout/single-answer-question";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page57Male() {
    return <SingleAnswerLayout
        previous={getGameRoute(PAGES.p56)}
        next={getGameRoute(PAGES.p58)}
        player={PLAYER.PARENT}>
        <SingleAnswerQuestion
            question={"Jak postrzegasz swoje dziecko?"}
            questionId={"m-4-3"}
            answers={[
                'To już duży chłopak, trzeba mu mówić jak jest i się nie cackać, bo sobie w życiu nie poradzi, świat nie jest przyjazny.',
                'Staram się zachować równowagę – mówię jak mi się coś nie podoba, jak coś źle zrobił i tyle samo staram się chwalić. Też nie za dużo, żeby nie wpadł w hurraoptymizm na swój temat.',
                'Staram się doceniać na każdym kroku. Uważam, że to bardzo ważne. Jeśli dziecko robi w mojej ocenie coś niewłaściwego, mówię o tym.'
            ]}
        />
    </SingleAnswerLayout>
}
