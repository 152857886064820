import { HEADS } from "../assets/heads"

export const DECOR_CONFIG = {
    STORY_1: [
        {
            src: HEADS.ADAM,
            side: 'left',
            position: .15
        },
        {
            src: HEADS.JUREK,
            side: 'right',
            position: .9
        }
    ],
    STORY_2_MALE: [
        {
            src: HEADS.KAZIK,
            side: 'left',
            position: .9
        },
        {
            src: HEADS.ADAM,
            side: 'right',
            position: .1
        }
    ],
    STORY_2_FEMALE: [
        {
            src: HEADS.KRYSIA,
            side: 'left',
            position: .9
        },
        {
            src: HEADS.ADAM,
            side: 'right',
            position: .1
        }
    ],
    STORY_3_MALE: [
        {
            src: HEADS.KAZIK,
            side: 'left',
            position: .3
        },
        {
            src: HEADS.SANDRA,
            side: 'right',
            position: 0
        },
        {
            src: HEADS.TADZIK,
            side: 'right',
            position: .9
        }],
    STORY_3_FEMALE: [
        {
            src: HEADS.KRYSIA,
            side: 'left',
            position: .3
        },
        {
            src: HEADS.SANDRA,
            side: 'right',
            position: 0
        },
        {
            src: HEADS.TADZIK,
            side: 'right',
            position: .9
        }],
    STORY_4_MALE: [
        {
            src: HEADS.KAZIK,
            side: 'left',
            position: .6
        },
        {
            src: HEADS.ADAM,
            side: 'right',
            position: .3
        }
    ],
    STORY_4_FEMALE: [
        {
            src: HEADS.KRYSIA,
            side: 'left',
            position: .4
        },
        {
            src: HEADS.ADAM,
            side: 'right',
            position: .7
        }
    ],
    STORY_5_MALE: [
        {
            src: HEADS.KAZIK,
            side: 'left',
            position: .4
        },
        {
            src: HEADS.ADAM,
            side: 'right',
            position: .7
        }
    ],
    STORY_5_FEMALE: [
        {
            src: HEADS.KRYSIA,
            side: 'left',
            position: .6
        },
        {
            src: HEADS.ADAM,
            side: 'right',
            position: .2
        }
    ],
    OPEN_QUESTIONS: [
        {
            src: HEADS.FILOZOF,
            side: 'left',
            position: .8
        }
    ]
}
