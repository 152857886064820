import PageLayout from "../../components/page-layout";
import { PLAYER } from "../../modules/main";
import useAnswer from "../../modules/main/hooks/use-answer";
import useGame from "../../modules/main/hooks/use-game";
import { getGameRoute, PAGES } from "../../routes/routes";
import Topic from "./topic";

export default function Page81Female() {
    const { gameState } = useGame();
    const childAnswer: string[] = useAnswer('m-7-1', PLAYER.CHILD);

    return <PageLayout previous={getGameRoute(PAGES.p80)} next={getGameRoute(PAGES.p82)}>
        <p>{gameState.child.name}, jak czułabyś się, słysząc takie słowa od rodzica?</p>
        {
            childAnswer.includes('0') && <Topic id="m-7-2-0"
                topic="Byłam niewyspana"
                texts={[
                    "Dlaczego się nie wyspałaś? Trzeba było się położyć wcześniej i nie grać tyle.",
                    "Jakby można było cofnąć czas, to co byś zmieniła w swoim postępowaniu?",
                    "Zdarza się, następnym razem musisz się wcześniej położyć i porządnie wyspać.",
                ]} />
        }
        {
            childAnswer.includes('1') && <Topic id="m-7-2-1"
                topic="Zjadł mnie stres"
                texts={[
                    "No ale co Cię stresuje? Przecież nie można tego nie zdać!",
                    "Inni też się tak stresowali?",
                    "Chcesz mi powiedzieć, czym się stresowałaś? Masz pomysł jak mogłabyś się zrelaksować następnym razem?",
                ]} />
        }
        {
            childAnswer.includes('2') && <Topic id="m-7-2-2"
                topic="Nie mogłam się skoncentrować."
                texts={[
                    "Ale to co tam się działo, że nie mogłaś się skoncentrować? Dyskoteka jakaś była?",
                    "Nic nowego, jakoś nie zaskakuje mnie to.",
                    "Ja też w takich sytuacjach mam problem z koncentracją. Chcesz posłuchać, jak staram się z tym sobie radzić?",
                ]} />
        }
        {
            childAnswer.includes('3') && <Topic id="m-7-2-3"
                topic="Nie przygotowałam się, nie powtórzyłam całego materiału."
                texts={[
                    "No nie, jak mogłaś nie powtórzyć tego?",
                    "Trzeba naprawdę mieć wywalone, żeby tego się nie nauczyć.",
                    "Rozumiem. Ciekaw jestem z czego wynika, że tego nie powtórzyłaś, powiesz mi?",
                ]} />
        }
        {
            childAnswer.includes('4') && <Topic id="m-7-2-4"
                topic="Miałam pecha, bo trafiły się akurat te zadania, z których nie jestem najlepsza."
                texts={[
                    "Na egzaminie nie pojawiło się nic, czego byście nie przerabiali w szkole. To nie jest wymówka.",
                    "Dużo osób miało takiego pecha?",
                    "Jak się z tym czujesz? Co planujesz zrobić teraz?",
                ]} />
        }
        {
            childAnswer.includes('5') && <Topic id="m-7-2-5"
                topic="To było dopiero pierwsze podejście."
                texts={[
                    "Ty to zawsze masz na wszystko dużo czasu. I wyszło jak zawsze.",
                    "Jakoś mnie to w ogóle nie dziwi.",
                    "Wiesz, właściwy egzamin też będzie Twoim pierwszym. Masz pomysły co zrobić w tej sytuacji?",
                ]} />
        }
    </PageLayout>
}
