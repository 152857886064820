import { forwardRef } from "react";
import { Link } from "react-router-dom"
import styled from "styled-components"

type Props = {
    to: string,
    label: string,
    isSubmit?: boolean,
    isDownload?: boolean,
    onClick?: any
}

const Container = styled.div`
    background: #BDBDBD;
    display: flex;
    flex-direction: column;
    border: 1px solid #BDBDBD;
    box-shadow: 1px 1px 2px 0 rgba(0,0,0, 1), -1px -1px 1px 1px rgba(255, 255, 255, 1);

    .top-bar {
        flex: 0 0 20px;
        background: #00007B;
    }

    .body {
        padding: 1em 1.5em;
        display: flex;
        justify-content: center;
    }

    a, button {
        border: 0;
        background: #BDBDBD;
        box-shadow: 1px 1px 2px 0 rgba(104, 104, 104, .8), inset 1px 1px 2px 0 rgba(255, 255, 255, .8);
        padding: .2em;

        color: black;
        text-decoration: none;

        text-align: center;

        &:hover {
            outline: 1px black dotted;
            outline-offset: -.1em;
        }
    }
`;

const NiceButton = forwardRef(({ label, to, isSubmit, onClick, isDownload }: React.PropsWithRef<Props>, ref: any) => {
    if (isSubmit) {
        return <Container>
            <div className="top-bar"></div>
            <div className="body">
                <button ref={ref} onClick={onClick}>
                    {label}
                </button>
            </div>
        </Container>
    } if (isDownload) {
        return <Container>
            <div className="top-bar"></div>
            <div className="body">
                <a ref={ref} href={to} onClick={onClick}>
                    {label}
                </a>
            </div>
        </Container>
    } else {
        return <Container>
            <div className="top-bar"></div>
            <div className="body">
                <Link ref={ref} to={to} onClick={onClick}>
                    {label}
                </Link>
            </div>
        </Container>
    }
});

export default NiceButton;
