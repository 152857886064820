import CompareAnswersLayout from "../../components/compare-answers-layout";
import { MODULE_8_QUESTIONS } from "../../modules/main/hooks/use-final-score";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page89() {
    return <CompareAnswersLayout
    previous={getGameRoute(PAGES.p88)}
    next={getGameRoute(PAGES.p90)}
    questions={MODULE_8_QUESTIONS} />
}
