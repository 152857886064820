import PlayerInfoLayout from "../../components/player-info-layout";
import { PLAYER } from "../../modules/main";
import { getGameRoute, PAGES } from "../../routes/routes";

export default function Page9() {

    return <PlayerInfoLayout
        previous={getGameRoute(PAGES.p8)}
        next={getGameRoute(PAGES.p10)}
        player={PLAYER.PARENT}
    />
}
