import PageLayout from "../../components/page-layout";
import { getGameRoute, PAGES } from "../../routes/routes";
import { DECOR_CONFIG } from "../../styles/decors";
import librus1Src from './male-librus-1.png';
import librus2Src from './male-librus-2.png';
import librus3Src from './male-librus-3.png';
import librus4Src from './male-librus-4.png';

export default function Page15Male() {
    return <PageLayout
            previous={getGameRoute(PAGES.p14)}
            next={getGameRoute(PAGES.p16)}
            decors={DECOR_CONFIG.STORY_2_FEMALE}
        >
        <p>Kolejne dwa miesiące nauki minęły jak gdyby nigdy nic. Pewnego dnia na skrzynkę Adama, taty Kazika, przychodzi powiadomienie: “Proszę zapoznać się z wynikami edukacyjnymi swojego dziecka przed zbliżającą się wywiadówką”. </p>
        <img src={librus3Src} alt="Librus Kazika. W ostatnim okresie otrzymał z języka polskiego oceny: 6,4 i 1. Z angielskiego ma trzy piątki. Ze sprawdzianu z matematyki otrzymał ocenę 3, ale poprawił na 4. Oprócz tego ma 3 i 4-. Z biologii i chemii 5, 4 i 3. Z fizyki 1, 4 i 4. Z historii trzy czwórki a z wychowania fizycznego trzy trójki." />
        <img src={librus2Src} alt="Na fizyce ma 90% obecności. Na języku polskim i matematyce 80%. Chemia i fizyka 70%. Na biologii 60% a na wuefie i angielskim 40%." />
        <img src={librus1Src} alt="Kazik był wolontariuszem w trakcie Światowego Dnia Ziemi i Szkolnego Festiwalu Filmowego. Uczestniczył również w slammie poetyckim. Nie brał udziału w dniu sportu i inicjatywie pomocy w lekcjach młodszym uczniom." />
        <img src={librus4Src} alt="Nauczycielka wuefu informuje, że Kazik nie przynosi stroju sportowego na lekcje. Nauczycielka języka angielskiego zwraca uwagę, że Kazik przeszkadza na lekcjach. Nauczyciel języka polskiego chwali Kazika za recytację jego autorskiego wiersza na slamie. Wychowawczyni prosi rodziców o rozmowę po zebraniu, bo Kazik nie chce jechać na zieloną szkołę." />
    </PageLayout>
}
