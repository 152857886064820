import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import Box from "../box";
import NiceButton from "../nice-button";
import { MAIN_MODULE_ACTION, PLAYER, useMainModuleDispatch } from "../../modules/main";
import { useNavigate } from "react-router";
import ColumnLayout from "../column-layout";

type Props = {
    previous: string,
    previousLabel?: string,
    next: string,
    nextLabel?: string,
    player: PLAYER
}

const Container = styled.main`
    label {
        display: block;
        margin: .5em 0;
    }
`;

export default function MultipleAnswerQuestionLayout({ children, previous, previousLabel, next, nextLabel, player }: React.PropsWithChildren<Props>) {

    const methods = useForm({
        reValidateMode: 'onSubmit'
    });
    const dispatch = useMainModuleDispatch();
    const navigate = useNavigate();

    const onSubmit = useCallback((data) => {

        Object.keys(data).forEach((key) => {
            dispatch({
                type: MAIN_MODULE_ACTION.SAVE_ANSWER,
                payload: {
                    player,
                    questionId: key,
                    answer: data[key]
                }
            });
        });

        navigate(next)

    }, [dispatch, next, navigate, player]);

    return <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ColumnLayout
                left={<NiceButton to={previous} label={previousLabel || 'Wróć'} />}
                right={<NiceButton isSubmit={true} to={next} label={nextLabel || 'Dalej'} />}
            >
                <Container>
                    <Box>
                        {children}
                    </Box>
                </Container>
            </ColumnLayout>
        </form>
    </FormProvider>
}
